import gql from 'graphql-tag';
import {DataValue, MutationFunc} from 'react-apollo';

import {TOP} from './topQuery';
import {location, depot} from '../relationalFragments';

export type DocumentApproval = 'PENDING' | 'REJECTED' | 'APPROVED';
export type DocumentActionLog = {
  id: string;
  status: DocumentApproval;
  createdAt: string;
  rejectCategory: {
    id: string;
    description: string;
  };
  message: string;
};
export type User = {
  id: string;
  email: string;
  name: string;
  storeName: string;
  approvalKtp: DocumentApproval;
  approvalNpwp: DocumentApproval;
  address: string;
  phone: string;
  storeAddress: string;
  storeTelephone: string;
  whatsapp: string;
  ktpNumber: string;
  npwpNumber: string;
  userUpload: {
    ktpPhoto: string;
    npwpPhoto: string;
    storePhoto: string;
  };
  tvipCredit: number;
  tvipPoint: number;
  segment: {id: string; title: string};
  subSegment: {id: string; title: string};
  priceSegment: {id: string; title: string; priceSegmentCode: string};
  top: TOP;
  location: {
    province: {id: string; name: string};
    city: {id: string; name: string};
    district: {id: string; name: string};
    subDistrict: {id: string; name: string};
    latitude: string;
    longitude: string;
    postalCode: string;
  };
  depot: {id: string; title: string};
  documentActionLogs: Array<DocumentActionLog>;
  suspended: boolean;
  suspendReason: string;
  cluster: {
    id: string;
    name: string;
  };
  active: boolean;
};
export type GetUsersResult = {users: Array<User>; count: number};
export type GetUserResult = {user: User};
export type GetUserVariable = {id: string};
export type GetUsersParams = {
  first: number;
  skip: number;
  where?: ObjectKey;
};
export type GetUsersData = DataValue<GetUsersResult, GetUsersParams>;
export type SuspendUserResult = {id: string};
export type SuspendUserParams = {id: string; suspendReason?: string};
export type SuspendUserMutationFn = MutationFunc<
  SuspendUserResult,
  SuspendUserParams
>;

export type UpdateUserResult = {id: string};
export type UpdateUserVariable = {
  id: string;
  user: {
    name?: string;
    phone?: string;
    email?: string;
    address?: string;
    storeName?: string;
    storeAddress?: string;
    storePhoto?: any; // TODO: image upload
    storeTelephone?: string;
    location?: {
      update: {
        postalCode?: string;
        latitude?: string;
        longitude?: string;
        province?: {connect: {id: string}};
        city?: {connect: {id: string}};
        district?: {connect: {id: string}};
        subDistrict?: {connect: {id: string}};
      };
    };
    cluster?: {
      id: string;
      name: string;
    };
    userUpload?: {
      update: {
        storePhoto: string;
      };
    };
  };
};
export type UpdateUserMutationFn = MutationFunc<
  UpdateUserResult,
  UpdateUserVariable
>;

export const USER_DATA_WITHOUT_CLUSTER = `
  id
  email
  name
  storeName
  approvalKtp
  approvalNpwp
  address
  phone
  storeAddress
  storeTelephone
  whatsapp
  ktpNumber
  npwpNumber
  userUpload {
    ktpPhoto
    npwpPhoto
    storePhoto
  }
  tvipCredit
  tvipPoint
  segment {
    id
    title
  }
  subSegment {
    id
    title
  }
  priceSegment {
    id
    title
    priceSegmentCode
  }
  top {
    id
    contractNumber
    contractStart
    details
    paymentPeriod
    isCredit
    creditLimit
    invoiceType
  }
  location ${location}
  ${depot}
  documentActionLogs{
    id
    status
    message
    createdAt
    rejectCategory{
      description
    } 
  }
  suspended
  suspendReason
  active
`;

export const USER_DATA = `
  ${USER_DATA_WITHOUT_CLUSTER}
  cluster {
    id
    name
  }
`;

export const GET_USERS = gql`
  query getUsers($first: Int, $skip: Int, $where: UserWhereInput) {
    users(first: $first, skip: $skip, where: $where) {
      ${USER_DATA}
    }
    count: dataCount(node: USER, userWhere: $where)
  }
`;

export const SUSPEND_USER = gql`
  mutation suspendUser($id: ID!, $suspendReason: String) {
    suspendUser(where: {id: $id}, suspendReason: $suspendReason) {
      id
    }
  }
`;

export const UNSUSPEND_USER = gql`
  mutation unsuspendUser($id: ID!) {
    unsuspendUser(where: {id: $id}) {
      id
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query getUserByID($id: ID!) {
    user(id: $id) {
      ${USER_DATA}
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($id: ID!, $user: UserUpdateInput!) {
    updateUserData(where: {id: $id}, data: $user) {
      id
    }
  }
`;
