import React, {useMemo} from 'react';
import {View, ViewStyle, StyleProp} from 'react-native';
import {graphql} from 'react-apollo';

import {GET_DEPOT_LIST, DepotListData} from '../../graphql/queries';

import {ChosenProps, Picker, Option} from '.';

type DepotListProps = {depotQuery: DepotListData};
type OwnProps = {
  isFilter?: boolean;
  showCoverage?: boolean;
  selectedDistrict?: Nullable<string>;
  selectedSubDistrict?: Nullable<string>;
  onDistrictChange?: (option: Nullable<Option>) => void;
  onSubDistrictChange?: (option: Nullable<Option>) => void;
  containerStyle?: StyleProp<ViewStyle>;
  hidden?: boolean;
} & ChosenProps;
type Props = DepotListProps & OwnProps;

export function DepotPicker({
  style,
  depotQuery,
  showCoverage,
  selectedOption,
  selectedDistrict,
  selectedSubDistrict,
  onChange,
  onDistrictChange,
  onSubDistrictChange,
  containerStyle,
  hidden = false,
  ...props
}: Props) {
  const depotOptions =
    depotQuery && depotQuery.depots
      ? depotQuery.depots.map(({id, title}) => ({
          value: id,
          label: title.toUpperCase(),
        }))
      : [];

  const districtOptions = useMemo(() => {
    if (showCoverage && selectedOption && depotQuery && depotQuery.depots) {
      const depotObject = depotQuery.depots.find(
        (depot) => depot.id === selectedOption,
      );

      if (depotObject) {
        return depotObject.coverage
          .map((coverage) => ({
            label: coverage.district.name.toUpperCase(),
            value: coverage.district.id,
          }))
          .filter(
            (district, index, self) =>
              self.findIndex((d) => d.value === district.value) === index,
          );
      }

      return [];
    }

    return [];
  }, [showCoverage, selectedOption, depotQuery]);

  const subDistrictOptions = useMemo(() => {
    if (showCoverage && selectedOption && depotQuery && depotQuery.depots) {
      const depotObject = depotQuery.depots.find(
        (depot) => depot.id === selectedOption,
      );

      if (depotObject) {
        return depotObject.coverage
          .filter((coverage) => coverage.district.id === selectedDistrict)
          .map((coverage) => ({
            label: coverage.subDistrict.name.toUpperCase(),
            value: coverage.subDistrict.id,
          }))
          .filter(
            (subDistrict, index, self) =>
              self.findIndex((d) => d.value === subDistrict.value) === index,
          );
      }

      return [];
    }

    return [];
  }, [depotQuery, selectedOption, showCoverage, selectedDistrict]);

  if (hidden) {
    return null;
  }

  return (
    <View style={containerStyle}>
      <Picker
        label="Depo"
        placeholder="Depo"
        style={[{zIndex: 3}, style]}
        isLoading={depotQuery.loading}
        selectedOption={selectedOption}
        options={depotOptions}
        onChange={(selected) => {
          onChange && onChange(selected);
        }}
        {...props}
      />
      {showCoverage && (
        <>
          <Picker
            label="Kecamatan"
            placeholder="Kecamatan"
            style={[{zIndex: 2}, style]}
            isLoading={depotQuery.loading}
            selectedOption={selectedDistrict || null}
            options={districtOptions}
            onChange={(selected) => {
              onDistrictChange && onDistrictChange(selected);
            }}
            {...props}
          />
          <Picker
            label="Kelurahan"
            placeholder="Kelurahan"
            style={style}
            isLoading={depotQuery.loading}
            selectedOption={selectedSubDistrict || null}
            options={subDistrictOptions}
            onChange={(selected) => {
              onSubDistrictChange && onSubDistrictChange(selected);
            }}
            {...props}
          />
        </>
      )}
    </View>
  );
}

export default graphql<OwnProps, DepotListData, {}, DepotListProps>(
  GET_DEPOT_LIST,
  {name: 'depotQuery'},
)(DepotPicker);
