import {AsyncStorage} from 'react-native';

export default {
  saveToken: (userToken: string) => {
    AsyncStorage.setItem('tvip-token', userToken);
  },
  saveRole: (role: string) => {
    AsyncStorage.setItem('role', role);
  },
  saveName: (name: string) => {
    AsyncStorage.setItem('name', name);
  },
  saveIsMixOrder: (isMixOrder: string) => {
    AsyncStorage.setItem('is-mix-order', isMixOrder);
  },
  getToken: () => {
    return AsyncStorage.getItem('tvip-token');
  },
  getRole: () => {
    return AsyncStorage.getItem('role');
  },
  getName: () => {
    return AsyncStorage.getItem('name');
  },
  getIsMixOrder: () => {
    return AsyncStorage.getItem('is-mix-order');
  },
  removeToken: () => {
    return AsyncStorage.removeItem('tvip-token');
  },
  removeRole: () => {
    return AsyncStorage.removeItem('role');
  },
  removeName: () => {
    return AsyncStorage.removeItem('name');
  },
  removeIsMixOrder: () => {
    return AsyncStorage.removeItem('is-mix-order');
  },
};
