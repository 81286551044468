const FONT_SIZE = {
  xsmall: 13,
  small: 15,
  medium: 18,
  large: 28,
  xlarge: 36,
};

const FONT_WEIGHT = {
  light: 'Rubik-Light',
  reg: 'Rubik-Regular',
  bold: 'Rubik-Medium',
  black: 'Rubik-Bold',
};

export {FONT_SIZE, FONT_WEIGHT};
