import { ID_POSTRESQL_STG, ID_POSTRESQL_PRO, ID_POSTRESQL_BY_ID } from '../constants/depoPostgreSQL';

export function convertPostgresqlIDToDepoStg(depo: string): string {
    // const kodeDepo: string = depo.split("-")[0];
    return ID_POSTRESQL_STG[depo].depo_id || '';
}

export function convertPostgresqlIDToDepoPro(depo: string): string {
    // const kodeDepo: string = depo.split("-")[0];
    return ID_POSTRESQL_PRO[depo].depo_id || '';
}

export function convertDepoToPostgresqlIDstg(depo: string): string {
    const kodeDepo: string = depo.split("-")[0];

    return ID_POSTRESQL_BY_ID[kodeDepo].postgre_id_staging || '';
}

export function convertDepoToPostgresqlIDpro(depo: string): string {
    const kodeDepo: string = depo.split("-")[0];

    return ID_POSTRESQL_BY_ID[kodeDepo].postgre_id_production || '';
}