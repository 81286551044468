import {ComponentClass, FC} from 'react';

import {
  AssignDriverListScene,
  DriverScene,
  NotFoundPage,
  VehicleScene,
  OrderScene,
  LoginScene,
  ForgotPasswordScene,
  SetNewPasswordScene,
} from '../scenes';
import {RITRawDataScene} from '../scenes/assignDriver/RIT/RITRawData';
import {RITRawTTATScene} from '../scenes/assignDriver/RIT/RITRawTTAT';

export type RouteItem = {
  path?: string;
  strict?: boolean;
  exact?: boolean;
  component: ComponentClass<any, any> | FC<any>;
  nickname?: string;
};

const DEFAULT_ROUTES: Array<RouteItem> = [
  {
    path: '/',
    strict: true,
    exact: true,
    component: LoginScene,
    nickname: 'Login',
  },
  {
    path: '/forgot-password',
    strict: true,
    exact: true,
    component: ForgotPasswordScene,
  },
  {
    path: '/set-new-password/:token',
    strict: true,
    exact: true,
    component: SetNewPasswordScene,
  },
  {component: LoginScene},
];

const AUTHENTICATED_ROUTES: Array<RouteItem> = [
  {
    path: '/',
    strict: true,
    exact: true,
    component: DriverScene,
    nickname: 'Root',
  },
  {
    path: '/drivers',
    strict: true,
    exact: true,
    component: DriverScene,
    nickname: 'Data Driver',
  },
  {
    path: '/vehicles',
    strict: true,
    exact: true,
    component: VehicleScene,
    nickname: 'Kapasitas Kendaraan',
  },
  {
    path: '/assign-driver',
    strict: true,
    exact: true,
    component: AssignDriverListScene,
    nickname: 'Assign Driver',
  },
  {
    path: '/assign-driver/ritase-raw-data/:id',
    strict: true,
    exact: true,
    component: RITRawDataScene,
    nickname: 'Data Ritase',
  },
  {
    path: '/assign-driver/ritase-raw-ttat/:id',
    strict: true,
    exact: true,
    component: RITRawTTATScene,
    nickname: 'Data TTAT',
  },
  {
    path: '/orders',
    strict: true,
    exact: true,
    component: OrderScene,
    nickname: 'Status Pengiriman',
  },
  {component: NotFoundPage},
];

export {DEFAULT_ROUTES, AUTHENTICATED_ROUTES};
