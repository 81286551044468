function shiftIndexUp<T>(array: Array<T>, index: number): Array<T> {
  if (index === 0) {
    return array;
  }
  let shiftedArray = array.map((value, indexArray) => {
    if (indexArray === index) {
      return array[indexArray - 1];
    } else if (indexArray === index - 1) {
      return array[index];
    }
    return value;
  });
  return shiftedArray;
}

function shiftIndexDown<T>(array: Array<T>, index: number): Array<T> {
  if (index === array.length - 1) {
    return array;
  }
  let shiftedArray = array.map((value, indexArray) => {
    if (indexArray === index) {
      return array[indexArray + 1];
    } else if (indexArray === index + 1) {
      return array[index];
    }
    return value;
  });
  return shiftedArray;
}

export {shiftIndexDown, shiftIndexUp};
