import React from 'react';
import {View, StyleSheet} from 'react-native';

import {Text} from '../core-ui';
import {RED, LIGHT_RED, GREEN, PALE_GREEN} from '../constants/colors';

type Props = {
  message: string;
  type: 'success' | 'error';
};

export default function MessagesBox(props: Props) {
  let {message, type} = props;
  return (
    <View style={styles[type]}>
      <Text size="xsmall" weight="light">
        {message}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  error: {
    padding: 10,
    borderWidth: 1,
    backgroundColor: LIGHT_RED,
    borderColor: RED,
    borderRadius: 3,
  },
  success: {
    padding: 10,
    borderWidth: 1,
    backgroundColor: PALE_GREEN,
    borderColor: GREEN,
    borderRadius: 3,
  },
});
