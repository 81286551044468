import React, {Fragment} from 'react';
import {View, StyleSheet} from 'react-native';
import {Mutation, compose} from 'react-apollo';

import {Text, Icon, Modal, Button} from '../../../core-ui';
import {Table, Query} from '../../../components';
import {GRAY, BAHAMA_BLUE, WHITE} from '../../../constants/colors';
import {
  DriverRITListResult,
  GET_DRIVER_RITS,
  DriverRITListParams,
} from '../../../graphql/queries';
import {
  SelectedDriver,
  TotalRemainingOrderRefetch,
} from '../AssignDriverListScene';
import {
  CUDDriverRITResult,
  DeleteDriverRITArgs,
  DELETE_DRIVER_RIT,
} from '../../../graphql/mutations';
import withToast, {ToastContextProps} from '../../../helpers/withToast';
import {Link} from 'react-router-dom';

type OwnProps = {
  onEditRIT: (ritID: string) => void;
  selectedDriver: SelectedDriver;
  closeRITModal: () => void;
  totalRefetchQuery: TotalRemainingOrderRefetch;
};

type State = {
  isShowDeleteModal: boolean;
  selectedRITID?: string;
};

type Props = OwnProps & ToastContextProps;

export class RITList extends React.Component<Props, State> {
  state: State = {
    isShowDeleteModal: false,
  };

  render() {
    return <Fragment>{this._renderTable()}</Fragment>;
  }

  _renderTable = () => {
    let {
      onEditRIT,
      selectedDriver: {driverNIK},
    } = this.props;
    const dataKey = 'driverRITs';
    let begin = new Date().setHours(0, 0, 0);
    let end = new Date().setHours(24, 0, 0);
    return (
      <Query<DriverRITListResult, DriverRITListParams>
        query={GET_DRIVER_RITS}
        variables={{
          where: {
            driver: {driverNIK},
            OR: [
              {completed_not: true},
              {
                createdAt_gte: new Date(begin).toISOString(),
                createdAt_lt: new Date(end).toISOString(),
              },
            ],
          },
          orderBy: 'id_DESC',
        }}
        fetchPolicy="network-only"
        key={dataKey}
        notifyOnNetworkStatusChange
      >
        {({data, loading}) => {
          return (
            <View style={styles.bodyWrapper}>
              {this._renderDeleteModal()}
              <Table
                data={(data && data.rITs) || []}
                dataCount={(data && data.rITs && data.rITs.length) || 0}
                resetPage={false}
                setResetPage={() => {}}
                isLoading={loading}
                page={0}
                showFooter={false}
                onChangePage={() => {}}
                rowsPerPage={25}
                structure={{
                  numberOfDestination: {
                    headerTitle: 'Jumlah Destinasi',
                    render: ({assignedOrder}) => (
                      <Text>{assignedOrder.length}</Text>
                    ),
                  },
                  status: {
                    render: ({started, completed}) => {
                      return (
                        <View style={styles.statusColumnContainer}>
                          <Text>
                            {started
                              ? 'JALAN'
                              : completed
                              ? 'SELESAI'
                              : 'BELUM JALAN'}
                          </Text>
                        </View>
                      );
                    },
                  },
                  deleteRIT: {
                    noHeaderName: true,
                    render: (rit) => {
                      return (
                        <View>
                          {!rit.started && !rit.completed && (
                            <Icon
                              size="small"
                              name="delete"
                              color={GRAY}
                              hoverColor={BAHAMA_BLUE}
                              onPress={() => {
                                this._showDeleteModal(rit.id);
                              }}
                            />
                          )}
                        </View>
                      );
                    },
                  },
                  editRIT: {
                    noHeaderName: true,
                    render: (rit) => {
                      return (
                        <View>
                          {!rit.started && !rit.completed && (
                            <Icon
                              size="small"
                              name="edit"
                              color={GRAY}
                              hoverColor={BAHAMA_BLUE}
                              onPress={() => {
                                onEditRIT(rit.id);
                              }}
                            />
                          )}
                        </View>
                      );
                    },
                  },
                  rawData: {
                    noHeaderName: true,
                    render: (rit) => {
                      return (
                        <View>
                          <Link to={`/assign-driver/ritase-raw-data/${rit.id}`}>
                            <Icon
                              size="small"
                              name="insert_drive_file"
                              color={GRAY}
                              hoverColor={BAHAMA_BLUE}
                            />
                          </Link>
                        </View>
                      );
                    },
                  },
                  rawTTAT: {
                    noHeaderName: true,
                    render: (rit) => {
                      return (
                        <View>
                          <Link to={`/assign-driver/ritase-raw-ttat/${rit.id}`}>
                            <Icon
                              size="small"
                              name="timeline"
                              color={GRAY}
                              hoverColor={BAHAMA_BLUE}
                            />
                          </Link>
                        </View>
                      );
                    },
                  },
                }}
                loadMore={() => {}}
                onChangeRowsPerPage={() => {}}
              />
            </View>
          );
        }}
      </Query>
    );
  };

  _showDeleteModal = (ritID: string) => {
    this.setState({
      isShowDeleteModal: true,
      selectedRITID: ritID,
    });
  };

  _closeDeleteModal = () => {
    this.setState({
      isShowDeleteModal: false,
    });
  };

  _onDeleteError = () => {
    let {openToast, closeRITModal} = this.props;
    openToast && openToast('fail', 'Ritase gagal dihapus.');
    this._closeDeleteModal();
    closeRITModal();
  };

  _onDeleteSuccess = () => {
    let {openToast, closeRITModal, totalRefetchQuery} = this.props;
    openToast && openToast('success', 'Ritase berhasil dihapus.');
    this._closeDeleteModal();
    totalRefetchQuery && totalRefetchQuery();
    closeRITModal();
  };

  _renderDeleteModal = () => {
    let {selectedRITID, isShowDeleteModal} = this.state;
    return (
      <Mutation<CUDDriverRITResult, DeleteDriverRITArgs>
        mutation={DELETE_DRIVER_RIT}
        onCompleted={this._onDeleteSuccess}
        onError={this._onDeleteError}
      >
        {(deleteRITProvider, {loading}) => {
          return (
            <Modal
              isVisible={isShowDeleteModal}
              title={'Hapus Ritase'}
              onClose={this._closeDeleteModal}
              isLoading={loading}
              description={
                'Ritase yang dipilih akan dihapus. Apakah Anda yakin?'
              }
              children={
                <View style={styles.modalView}>
                  <View style={styles.button}>
                    <Button
                      color="secondary"
                      inverted={true}
                      text="Batal"
                      onPress={this._closeDeleteModal}
                      loadingColor={WHITE}
                    />
                  </View>
                  <View style={styles.button}>
                    <Button
                      color="primary"
                      text="Hapus"
                      isLoading={loading}
                      onPress={() => {
                        selectedRITID &&
                          deleteRITProvider({
                            variables: {where: {id: selectedRITID}},
                          });
                      }}
                      loadingColor={WHITE}
                    />
                  </View>
                </View>
              }
            />
          );
        }}
      </Mutation>
    );
  };
}

const styles = StyleSheet.create({
  bodyWrapper: {
    paddingTop: 20,
  },
  button: {
    marginHorizontal: 10,
  },
  actionTableWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  rootContentModal: {
    flexDirection: 'row',
    marginTop: 40,
    marginBottom: 40,
  },
  statusColumnContainer: {width: 440},
  leftContentModal: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 4,
  },
  rightContentModal: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 8,
  },
  modalView: {flexDirection: 'row', justifyContent: 'flex-end'},
});

export default compose(withToast)(RITList);
