import React from 'react';
import {Query, QueryProps, QueryResult} from 'react-apollo';
import {View, StyleSheet, StyleProp, ViewStyle} from 'react-native';

import {ErrorScreen} from '.';
import {Loading} from '../core-ui';

type CustomProps = {
  keyData?: string;
  disableError?: boolean;
  disableLoading?: boolean;
  style?: StyleProp<ViewStyle>;
};

type Props<TData, TVars> = QueryProps<TData, TVars> & CustomProps;

export default <TData extends ObjectKey = {}, TVars extends ObjectKey = {}>({
  disableLoading,
  disableError,
  keyData,
  style,
  ...props
}: Props<TData, TVars>) => {
  return (
    // NOTE: a react type-definition issue regarding IsExactlyAny conditional. This is the proposed workaround by the author: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/32588#issuecomment-459060799
    <Query<TData, TVars>
      {...(props as JSX.LibraryManagedAttributes<typeof Query, typeof props>)}
    >
      {({loading, error, data, ...result}: QueryResult<TData, TVars>) => {
        let isLoading = keyData
          ? // NOTE: if keyData is given, check whether the property keyData exists in data to decide whether it's finished loading or not
            data && !data[keyData]
            ? true
            : loading && false
          : loading;
        if (error && !disableError) {
          return (
            <View style={[styles.loadingContainer, style]}>
              <ErrorScreen detailMessage={error.message} />
            </View>
          );
        }
        if (isLoading && !disableLoading) {
          return (
            <View style={[styles.loadingContainer, style]}>
              <Loading />
            </View>
          );
        }

        return props.children({loading, error, data, ...result});
      }}
    </Query>
  );
};

const styles = StyleSheet.create({
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
