import React, {Fragment} from 'react';
import {View, StyleSheet} from 'react-native';
import {Table, RowsPerPage, Query, ErrorScreen} from '../../components';
import {
  VehicleListResult,
  VehicleListParams,
  GET_VEHICLES,
  GET_SEARCH_STATE,
  SearchStateProps,
} from '../../graphql/queries';
import {SearchState} from '../../graphql/localState';
import {compose, graphql} from 'react-apollo';
import {fetchMoreItems, refetchItems} from '../../helpers';

type State = {
  page: number;
  rowsPerPage: RowsPerPage;
};

type SceneProps = {
  renderSyncModal: (refetchFn: () => void) => void;
  resetPage: boolean;
  setResetPage: (reset: boolean) => void;
};

type Props = SceneProps & SearchStateProps;

export class VehicleMasterList extends React.Component<Props, State> {
  state: State = {
    page: 0,
    rowsPerPage: 10,
  };

  render() {
    return <Fragment>{this._renderTable()}</Fragment>;
  }

  _getQueryWhere = () => {
    let {
      searchStateQuery: {searchState},
    } = this.props;
    if (searchState) {
      let searchText = searchState.searchedString.toLowerCase();
      return {
        OR: [{licensePlate_contains: searchText}],
      };
    }
    return {};
  };

  _renderTable = () => {
    let {rowsPerPage, page} = this.state;
    let {resetPage, setResetPage} = this.props;
    const dataKey = 'vehicles';
    return (
      <Query<VehicleListResult, VehicleListParams>
        query={GET_VEHICLES}
        variables={{
          where: this._getQueryWhere(),
          first: rowsPerPage,
          skip: 0,
        }}
        fetchPolicy="network-only"
        keyData={dataKey}
        notifyOnNetworkStatusChange
      >
        {({data, loading, error, fetchMore}) => {
          if (error) {
            return (
              <View style={styles.emptyContainer}>
                <ErrorScreen detailMessage={error.message} />
              </View>
            );
          } else if (data) {
            return (
              <View style={styles.bodyWrapper}>
                {this.props.renderSyncModal(() =>
                  refetchItems(fetchMore, {
                    query: GET_VEHICLES,
                    variables: {
                      where: this._getQueryWhere(),
                      first: rowsPerPage,
                      skip: page * rowsPerPage,
                    },
                    dataKey,
                    rowsPerPage,
                    page,
                  }),
                )}
                <Table
                  data={data.vehicles || []}
                  dataCount={data.count || 0}
                  resetPage={resetPage}
                  setResetPage={setResetPage}
                  isLoading={loading}
                  page={page}
                  onChangePage={(nextPage) => this.setState({page: nextPage})}
                  rowsPerPage={rowsPerPage}
                  structure={{
                    licensePlate: {headerTitle: 'Nomor Polisi'},
                    brandName: {headerTitle: 'Jenis Kendaraan'},
                    typeID: {
                      alias: 'vehicleType.typeID',
                      headerTitle: 'Kode Tipe Kendaraan',
                    },
                    containerType: {headerTitle: 'Loading Type'},
                    vehicleStatus: {headerTitle: 'Status Kendaraan'},
                    info: {
                      alias: 'vehicleType.info',
                      headerTitle: 'Keterangan',
                    },
                  }}
                  loadMore={({first, skip}) => {
                    fetchMoreItems(fetchMore, {
                      query: GET_VEHICLES,
                      variables: {
                        where: this._getQueryWhere(),
                        first,
                        skip,
                      },
                      dataKey,
                    });
                  }}
                  onChangeRowsPerPage={(newRowsPerPage) =>
                    this.setState({
                      rowsPerPage: newRowsPerPage,
                      page: 0,
                    })
                  }
                />
              </View>
            );
          }
          return null;
        }}
      </Query>
    );
  };
}

const styles = StyleSheet.create({
  bodyWrapper: {
    paddingTop: 20,
  },
  emptyContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default compose(
  graphql<{}, SearchState, {}, SearchStateProps>(GET_SEARCH_STATE, {
    name: 'searchStateQuery',
  }),
)(VehicleMasterList);
