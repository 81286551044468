import React, {Component, createRef, ReactNode, RefObject} from 'react';
import {View, StyleSheet, StyleProp, ViewStyle} from 'react-native';
import {findDOMNode} from 'react-dom';

type Props = {
  style?: StyleProp<ViewStyle>;
  onSubmit: () => void | Promise<any>;
  children: ReactNode;
};

export default class FormView extends Component<Props> {
  _ref: RefObject<View> = createRef();

  componentDidMount() {
    let form = this._ref.current ? findDOMNode(this._ref.current) : null;
    if (form) {
      form.addEventListener('submit', this._onSubmit);
    }
  }

  componentWillUnmount() {
    let form = this._ref.current ? findDOMNode(this._ref.current) : null;
    if (form) {
      form.removeEventListener('submit', this._onSubmit);
    }
  }

  render() {
    let {onSubmit, style, children, ...otherProps} = this.props;
    return (
      <View
        {...otherProps}
        accessibilityRole="form"
        ref={this._ref}
        style={style}
      >
        {children}
        <View style={styles.hidden}>
          <button type="submit" tabIndex={-1} />
        </View>
      </View>
    );
  }

  _onSubmit = (event: Event) => {
    event.preventDefault();
    let {onSubmit} = this.props;
    onSubmit && onSubmit();
  };
}

let styles = StyleSheet.create({
  hidden: {
    width: 0,
    height: 0,
    overflow: 'hidden',
    position: 'absolute',
  },
});
