import React, {Component} from 'react';
import {graphql} from 'react-apollo';

import {
  GET_SUB_DISTRICT_LIST,
  SubDistrictListData,
  SubDistrictListParams,
} from '../../graphql/queries';

import {ChosenProps, Picker} from '.';

type LocationParams = {
  district?: Nullable<string>;
};

type SubDistrictListProps = {
  subDistrictQuery: Array<{value: string; label: string}>;
  loading: boolean;
};
type OwnProps = {isFilter?: boolean; disabled?: boolean} & ChosenProps &
  LocationParams;
type Props = SubDistrictListProps & OwnProps;

export class SubDistrictPicker extends Component<Props> {
  render() {
    let {
      disabled,
      subDistrictQuery,
      style,
      loading,
      ...otherProps
    } = this.props;
    return (
      <Picker
        label="Kelurahan"
        placeholder="Kelurahan"
        style={style}
        isLoading={loading}
        options={disabled ? [] : subDistrictQuery}
        {...otherProps}
      />
    );
  }
}

export default graphql<
  OwnProps,
  SubDistrictListData,
  SubDistrictListParams,
  SubDistrictListProps
>(GET_SUB_DISTRICT_LIST, {
  options: ({district}) => {
    return {
      variables: district ? {district} : {},
    };
  },
  props: (p) => {
    return {
      ...p,
      subDistrictQuery:
        (p.data &&
          p.data.subDistricts &&
          p.data.subDistricts.map(({id, name}) => ({
            value: id,
            label: name.toUpperCase(),
          }))) ||
        [],
      loading: p.data ? p.data.loading : false,
    };
  },
})(SubDistrictPicker);
