import {MutationFunc} from 'react-apollo';
import gql from 'graphql-tag';

export type RequestResetPasswordParams = {
  email: string;
  path: string;
};

export type RequestResetPasswordMutation = MutationFunc<
  null,
  RequestResetPasswordParams
>;

export type ResetPasswordParams = {
  token: string;
  newPassword: string;
};

export type ResetPasswordMutation = MutationFunc<null, ResetPasswordParams>;

export const CMS_REQUEST_PASSWORD = gql`
  mutation CmsRequestPasswordReset($path: String!, $email: String!) {
    cmsRequestPasswordReset(baseLink: $path, email: $email)
  }
`;

export const CMS_RESET_PASSWORD = gql`
  mutation CmsPasswordReset($token: String!, $newPassword: String!) {
    cmsPasswordReset(token: $token, newPassword: $newPassword)
  }
`;
