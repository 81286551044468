import React, {ReactNode} from 'react';
import {
  Text as TextComp,
  StyleSheet,
  StyleProp,
  TextStyle,
  TextProps,
} from 'react-native';
import {FONT_SIZE, FONT_WEIGHT} from '../constants/textPresets';
import {BLACK, PRIMARY} from '../constants/colors';
import '../assets/css/index.css';

export type Props = TextProps & {
  size?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
  weight?: 'light' | 'reg' | 'bold' | 'black';
  italic?: boolean;
  primary?: boolean;
  color?: string;
  style?: StyleProp<TextStyle>;
  children?: ReactNode;
};

function Text(props: Props) {
  let {
    primary,
    weight,
    italic,
    style,
    color,
    size,
    children,
    ...otherProps
  } = props;
  let fontSize = FONT_SIZE[size || 'medium'];
  let fontFamily = FONT_WEIGHT[weight || 'reg'];
  let textStyle: StyleProp<TextStyle> = [
    styles.default,
    {fontSize},
    {color},
    {fontFamily},
    italic && styles.italic,
    primary && styles.primary,
    style,
  ];
  return (
    <TextComp style={textStyle} {...otherProps}>
      {children}
    </TextComp>
  );
}

export default Text;

const styles = StyleSheet.create({
  default: {
    fontSize: FONT_SIZE.medium,
    fontWeight: '300',
    color: BLACK,
    fontFamily: 'Rubik-Light',
  },
  italic: {
    fontStyle: 'italic',
  },
  primary: {
    color: PRIMARY,
  },
});
