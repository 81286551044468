import React, {Component} from 'react';
import {ApolloProvider} from 'react-apollo';
import MainRoute from './routes/MainRoute';

import * as Sentry from '@sentry/browser';
import {View} from 'react-native';
import {ToastProvider} from './core-ui/ToastContext';
import {client} from './graphql/client';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
  });
}

export default class App extends Component {
  render() {
    return (
      <View style={{flex: 1, height: '100%'}}>
        <ApolloProvider client={client}>
          <ToastProvider>
            <MainRoute />
          </ToastProvider>
        </ApolloProvider>
      </View>
    );
  }
}
