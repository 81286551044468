import React, {Component, Fragment} from 'react';
import {
  FlatList,
  Image,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import {compose, graphql, MutationFn, Subscription} from 'react-apollo';
import {withRouter, Link, RouteComponentProps} from 'react-router-dom';
import {ClickAwayListener} from '@material-ui/core';

import {
  GET_NEW_ORDER_NOTIFICATION,
  GET_TOTAL_ORDER_TO_ASSIGN,
  GET_TOTAL_REMAINING_ORDER,
  GET_TOTAL_UNPROCESSED_ORDER,
  TotalOrderToAssign,
  TotalRemainingOrderResult,
  USER_LOGOUT,
} from '../../graphql/queries';
import {Icon, Loading, Text} from '../../core-ui';
import {
  WHITE,
  DARK_GRAY,
  PRIMARY,
  GRAY,
  RED_MARKER,
} from '../../constants/colors';
import {logo1, logo2} from '../../assets';
import {NAVIGATION_ITEMS} from './navbarUtils';
import Query from '../Query';

export type NavigationMenu = {
  logo: IconKey;
  label: string;
  route: string;
};

type ItemNavigation = {
  item: NavigationMenu;
  index: number;
};

type Props = RouteComponentProps & {
  isFormOpened: boolean;
  userLogout?: MutationFn;
};

type State = {
  isDrawerOpened: boolean;
  isModalVisible: boolean;
  routeToNavigate: string;
};

export class Navbar extends Component<Props, State> {
  state: State = {
    isDrawerOpened: false,
    isModalVisible: false,
    routeToNavigate: this.props.location.pathname,
  };

  componentDidMount() {
    if (this.props.location.pathname === '/') {
      this.setState({routeToNavigate: NAVIGATION_ITEMS[0].route});
    }
  }

  render() {
    return <Fragment>{this._renderNavBar()}</Fragment>;
  }

  _renderNavBar() {
    let {isDrawerOpened} = this.state;
    return (
      <ClickAwayListener
        onClickAway={() =>
          isDrawerOpened && this.setState({isDrawerOpened: !isDrawerOpened})
        }
      >
        <View style={[styles.root, isDrawerOpened && styles.rootOpened]}>
          <View>
            {isDrawerOpened ? this._renderArrowIcon() : this._renderMenuIcon()}
          </View>
          {this._renderNavigationTab()}
        </View>
      </ClickAwayListener>
    );
  }

  _renderMenuIcon() {
    let {isDrawerOpened} = this.state;
    return (
      <Icon
        name="menu"
        color={DARK_GRAY}
        style={isDrawerOpened ? styles.menuIconOpened : styles.menuIconClosed}
        onPress={this._expandDrawer}
      />
    );
  }

  _renderArrowIcon() {
    let {isDrawerOpened} = this.state;
    return (
      <View style={{flexDirection: 'row'}}>
        <Icon
          name="close"
          color={DARK_GRAY}
          style={
            isDrawerOpened ? styles.arrowIconOpened : styles.arrowIconClosed
          }
          onPress={this._expandDrawer}
        />
        {/* TODO: remove link when we don't need it anymore */}
        <Link to="/">
          <Image
            source={{uri: logo1}}
            style={styles.logo}
            resizeMode="contain"
          />
        </Link>
        <Image
          source={{uri: logo2}}
          style={[styles.logo, styles.logo2]}
          resizeMode="contain"
        />
      </View>
    );
  }

  _renderNavigationTab() {
    return (
      <FlatList
        data={NAVIGATION_ITEMS}
        extraData={this.state}
        keyExtractor={(item) => item.label}
        renderItem={({item, index}) =>
          this._renderItemNavigation({item, index})
        }
        ListFooterComponent={this._renderFooterNavigation}
      />
    );
  }

  _renderItemNavigation = ({item}: ItemNavigation) => {
    let {location} = this.props;
    let {isDrawerOpened, isModalVisible, routeToNavigate} = this.state;
    let {logo, label, route} = item;
    let path = isModalVisible ? location.pathname : routeToNavigate;
    let isCurrentlySelected = path.startsWith(route);
    let selectedColor = isCurrentlySelected ? PRIMARY : DARK_GRAY;
    return (
      <TouchableOpacity onPress={() => this._onNavItemClick(route)}>
        <Query<TotalOrderToAssign>
          query={GET_TOTAL_REMAINING_ORDER}
          disableLoading
        >
          {({data, refetch, loading}) => {
            const totalOrder = data?.orderCount || 0;

            return (
              <Subscription
                subscription={GET_NEW_ORDER_NOTIFICATION}
                onSubscriptionData={() => refetch()}
              >
                {() => (
                  <View style={styles.menuItem}>
                    <View
                      style={{
                        position: 'relative',
                      }}
                    >
                      <Icon
                        isCustomSVG
                        name={logo}
                        color={selectedColor}
                        size="xlarge"
                        style={styles.menuIcon}
                      />
                      {route === '/assign-driver'
                        ? !isDrawerOpened &&
                          totalOrder > 0 && (
                            <View
                              style={{
                                width: 20,
                                height: 20,
                                top: 0,
                                borderRadius: 9999,
                                backgroundColor: RED_MARKER,
                                position: 'absolute',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                right: 10,
                              }}
                            >
                              {loading ? (
                                <Loading size="small" />
                              ) : (
                                <Text
                                  style={{
                                    fontSize: totalOrder >= 100 ? 10 : 12,
                                  }}
                                  color="white"
                                >
                                  {totalOrder}
                                </Text>
                              )}
                            </View>
                          )
                        : null}
                    </View>
                    <Text
                      weight="bold"
                      size="small"
                      color={selectedColor}
                      style={[
                        styles.text,
                        isDrawerOpened && styles.textTransition,
                      ]}
                    >
                      {route === '/assign-driver' ? (
                        <View
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row',
                          }}
                        >
                          <Text>{label}</Text>
                          {totalOrder > 0 && (
                            <View
                              style={{
                                paddingVertical: 4,
                                paddingHorizontal: 8,
                                backgroundColor: RED_MARKER,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: 999999,
                                marginLeft: 5,
                              }}
                            >
                              <Text color="white" size="small">
                                {totalOrder}
                              </Text>
                            </View>
                          )}
                        </View>
                      ) : (
                        label
                      )}
                    </Text>
                  </View>
                )}
              </Subscription>
            );
          }}
        </Query>
      </TouchableOpacity>
    );
  };

  _renderFooterNavigation = () => {
    return (
      <View>
        <View style={styles.separatorFooter} />
        {this._renderFooterItem('Keluar', 'input', this._handleLogout)}
      </View>
    );
  };

  _renderFooterItem = (
    text: string,
    iconName: IconKey,
    action: () => void,
    route?: string,
  ) => {
    let {isDrawerOpened, isModalVisible, routeToNavigate} = this.state;
    let path = isModalVisible ? location.pathname : routeToNavigate;
    let isCurrentlySelected = route && path.startsWith(route);
    let selectedColor = isCurrentlySelected ? PRIMARY : DARK_GRAY;
    return (
      <TouchableOpacity onPress={action} style={{flexDirection: 'row'}}>
        <View style={[styles.menuItem, styles.footerItem]}>
          <Icon
            name={iconName}
            color={selectedColor}
            style={{
              marginRight: 20,
            }}
          />
          <Text
            weight="bold"
            size="small"
            color={selectedColor}
            style={[styles.text, isDrawerOpened && styles.textTransition]}
          >
            {text}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  _onNavItemClick = (route: string) => {
    this.setState({routeToNavigate: route}, () => this._handleOnClick(route));
  };

  _onModalSubmit = () => {
    this.setState(
      {
        isModalVisible: false,
      },
      () => this._navigateTo(),
    );
  };

  _onModalClose = () =>
    this.setState({
      isModalVisible: false,
    });

  _navigateTo = (route?: string) => {
    let {history} = this.props;
    history && history.push(route ? route : this.state.routeToNavigate);
  };

  _handleOnClick = (route: string) => {
    if (this.props.isFormOpened) {
      this.setState({isModalVisible: true});
    } else {
      this._navigateTo(route);
    }
  };

  _expandDrawer = () => {
    this.setState({
      isDrawerOpened: !this.state.isDrawerOpened,
    });
  };

  _handleLogout = async () => {
    let {userLogout} = this.props;
    userLogout && (await userLogout());
    this._navigateTo('/');
  };
}

const styles = StyleSheet.create({
  root: {
    position: 'absolute',
    backgroundColor: WHITE,
    borderColor: GRAY,
    borderWidth: 0.3,
    height: '100%',
    overflow: 'hidden',
    width: 80,
    boxShadow: `1px 5px 15px ${DARK_GRAY}`,
    transitionProperty: 'width',
    transitionDuration: '0.2s',
    transitionTimingFunction: 'ease-out',
  },
  rootOpened: {
    width: 280,
    transitionProperty: 'width',
    transitionDuration: '0.15s',
    transitionTimingFunction: 'ease',
  },
  text: {
    transitionProperty: 'opacity',
    transitionDuration: '0.2s',
    opacity: 0,
  },
  menuIconOpened: {
    transitionProperty: 'opacity',
    transitionDuration: '0.1s',
    opacity: 0,
    paddingLeft: 25,
    paddingTop: 30,
    marginBottom: 30,
  },
  menuIconClosed: {
    transitionProperty: 'opacity',
    transitionDuration: '0.2s',
    opacity: 1,
    paddingTop: 30,
    marginBottom: 30,
  },
  arrowIconOpened: {
    transitionProperty: 'margin',
    transitionTimingFunction: 'ease-out',
    transitionDuration: '0.2s',
    opacity: 1,
    paddingLeft: 25,
    paddingTop: 30,
    marginBottom: 30,
  },
  arrowIconClosed: {
    transitionProperty: 'opacity, margin',
    transitionDuration: '0.2s, 0.2s',
    transitionTimingFunction: 'ease',
    marginLeft: 0,
    opacity: 0,
    paddingLeft: 30,
    paddingTop: 30,
    marginBottom: 30,
  },
  textTransition: {
    transitionProperty: 'opacity',
    transitionDuration: '0.5s',
    opacity: 1,
  },
  logo: {
    width: 60,
    height: 62,
    marginLeft: 15,
    marginTop: 15,
  },
  logo2: {
    marginLeft: 10,
  },
  menuItem: {
    flex: 1,
    alignItems: 'center',
    flexDirection: 'row',
    paddingLeft: 6,
  },
  menuIcon: {marginRight: 30, outline: 'none'},
  separatorFooter: {
    borderWidth: 0.5,
    borderColor: GRAY,
    marginHorizontal: 15,
  },
  footerItem: {
    height: 64,
    marginLeft: 15,
  },
});

export default compose(
  withRouter,
  graphql(USER_LOGOUT, {name: 'userLogout'}),
)(Navbar);
