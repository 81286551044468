import React, {Component} from 'react';
import {StyleProp, View, ViewStyle} from 'react-native';

import {ChosenProps, Picker} from '.';

type OwnProps = {
  isFilter?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
} & ChosenProps;
type Props = OwnProps;

type State = {
  mixOrderID: Nullable<string>;
};

export class MixOrderPicker extends Component<Props, State> {
  state: State = {
    mixOrderID: this.props.selectedOption || null,
  };

  render() {
    let {
      style,
      selectedOption,
      onChange,
      containerStyle,
      ...otherProps
    } = this.props;

    let mixOrderOptions: any = ['all', 'galon', 'box dan galon'];
    let listMixOrderOptions: any = mixOrderOptions
      ? mixOrderOptions.map((item: string) => ({
          value: item,
          label: item === 'all' ? 'SEMUA' : item.toUpperCase(),
        }))
      : [];

    return (
      <View style={containerStyle}>
        <Picker
          label="Mix Order"
          placeholder="Mix Order"
          style={[{zIndex: 3}, style]}
          isLoading={false}
          selectedOption={selectedOption}
          options={listMixOrderOptions}
          onChange={(selected) => {
            this.setState({
              mixOrderID: selected ? selected.value : selected,
            });
            onChange && onChange(selected);
          }}
          {...otherProps}
        />
      </View>
    );
  }
}

export default MixOrderPicker;
