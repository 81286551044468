import React from 'react';
import {View, StyleSheet} from 'react-native';
import {Text, Icon} from '../core-ui';
import {TABLE} from '../constants/colors';

type Props = {
  text: string;
};

export default function NoDataPlaceholder(props: Props) {
  let {text} = props;
  return (
    <View style={styles.nodata}>
      <Icon isCustomSVG disabled name="noData" color={TABLE.NO_DATA} />
      <View style={styles.noDataText}>
        <Text color={TABLE.BORDER} weight="light">
          {text}
        </Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  nodata: {
    flex: 1,
    alignSelf: 'center',
    justifyContent: 'center',
    paddingVertical: 100,
  },
  noDataText: {
    alignItems: 'center',
    marginTop: 30,
  },
});
