import gql from 'graphql-tag';
import {DataValue} from 'react-apollo';

type BaseLocation = {
  id: string;
  name: string;
};

export type Province = BaseLocation & {cities: Array<City>};
export type ProvinceList = {provinces: Array<Province>};
export type ProvinceListData = DataValue<ProvinceList, {}>;
export const GET_PROVINCE_LIST = gql`
  query getProvinceList {
    provinces {
      id
      name
      cities
    }
  }
`;

// ------------------------------------------------------------

export type ProvinceNameList = {provinces: Array<BaseLocation>};
export type ProvinceNameListData = DataValue<ProvinceNameList, {}>;
export const GET_PROVINCE_NAME_LIST = gql`
  query getProvinceNameList {
    provinces {
      id
      name
    }
  }
`;

// ------------------------------------------------------------

export type City = BaseLocation & {districts: Array<District>};
export type CityListParams = {province?: string};
export type CityList = {cities: Array<City>};
export type CityListData = DataValue<CityList, CityListParams>;
export const GET_CITY_LIST = gql`
  query getCityList($province: String) {
    cities(province: $province) {
      id
      name
      districts
    }
  }
`;

// ------------------------------------------------------------

export type CityNameList = {cities: Array<BaseLocation>};
export type CityNamelListData = DataValue<CityNameList, CityListParams>;
export const GET_CITY_NAME_LIST = gql`
  query getCityNameList($province: String) {
    cities(province: $province) {
      id
      name
    }
  }
`;

// ------------------------------------------------------------

export type District = BaseLocation & {subDistricts: Array<SubDistrict>};
export type DistrictListParams = {city?: string};
export type DistrictList = {districts: Array<District>};
export type DistrictListData = DataValue<DistrictList, DistrictListParams>;
export const GET_DISTRICT_LIST = gql`
  query getDistrictList($city: String) {
    districts(city: $city) {
      id
      name
      subDistricts
    }
  }
`;

// ------------------------------------------------------------

export type DistrictNameList = {districts: Array<BaseLocation>};
export type DistrictNameListData = DataValue<
  DistrictNameList,
  DistrictListParams
>;
export const GET_DISTRICT_NAME_LIST = gql`
  query getDistrictNameList($city: String) {
    districts(city: $city) {
      id
      name
    }
  }
`;

// ------------------------------------------------------------

export type SubDistrict = BaseLocation;
export type SubDistrictListParams = {district?: string};
export type SubDistrictList = {subDistricts: Array<SubDistrict>};
export type SubDistrictListData = DataValue<
  SubDistrictList,
  SubDistrictListParams
>;
export const GET_SUB_DISTRICT_LIST = gql`
  query getSubDistrictList($district: String) {
    subDistricts(district: $district) {
      id
      name
    }
  }
`;
