import React, {Fragment} from 'react';
import {StyleSheet, View} from 'react-native';
import {
  DepotPicker,
  MixOrderPicker,
  Popover,
  PopoverTableCard,
  Query,
  ResetFilterButton,
  RowsPerPage,
  SubDistrictPicker,
  Table,
} from '../../../../components';
import {BLACK, NAVY_BLUE} from '../../../../constants/colors';
import {Button, Text} from '../../../../core-ui';
import {
  DriverOrder,
  DriverOrdersParams,
  DriverOrdersResult,
  GET_DRIVER_ORDERS,
  TotalOrder,
} from '../../../../graphql/queries';
import {SelectedDriver} from '../../AssignDriverListScene';

import {asyncStorage} from '../../../../helpers';

type Props = {
  selectedDriver: SelectedDriver;
  onNextPress: () => void;
  onSelectedChange: (Purchases: Array<DriverOrder>) => void;
  onOrderListQueryComplete: (data: {
    totalBox: number;
    totalGallon: number;
    totalOrder: number;
  }) => void;
  selectedPurchases: Array<DriverOrder>;
  editRITID?: string;
  setCapacityLimit: (flag: boolean) => void;
  isCapacityLimit: boolean;
  role: string;
  onMixOrderFilterChange: (mixType: string) => void;
};

type State = {
  page: number;
  resetPage: boolean;
  rowsPerPage: RowsPerPage;
  selectedSubDistrict: Nullable<string>; // This is ID For subDistrictPicker
  selectedSubDistrictLabel: Nullable<string>; // This is label(name) For local filter
  selectedDepo: Nullable<string>;
  selectedTypeOrder: Nullable<string>;
  nameUserDepo: string;
  idPostresqlDepo: string;
};

export class OrderList extends React.Component<Props, State> {
  state: State = {
    page: 0,
    rowsPerPage: 10,
    resetPage: false,
    selectedSubDistrict: null,
    selectedSubDistrictLabel: null,
    selectedDepo: null,
    selectedTypeOrder: 'all',
    nameUserDepo: '',
    idPostresqlDepo: '',
  };

  componentDidUpdate(prevProps: Props) {
    let {
      selectedDriver,
      selectedPurchases,
      setCapacityLimit,
      isCapacityLimit,
    } = this.props;

    if (prevProps.selectedPurchases !== selectedPurchases) {
      // For checking limit capacity.
      let allTotalOrder: Array<TotalOrder> = [];
      selectedPurchases.forEach(
        ({totalOrder}) => (allTotalOrder = [...allTotalOrder, ...totalOrder]),
      );
      let totalQty = 0;
      allTotalOrder.forEach(
        ({quantity, unitQuantity, uom}) =>
          uom === selectedDriver.loadType &&
          (totalQty += quantity * unitQuantity),
      );
      if (totalQty >= selectedDriver.maxCapacity) {
        if (isCapacityLimit === false) {
          setCapacityLimit(true);
        }
      } else if (isCapacityLimit === true) {
        setCapacityLimit(false);
      }
    }
  }

  async componentDidMount() {
    await asyncStorage.saveIsMixOrder('');
  }

  render() {
    let {onNextPress} = this.props;
    return (
      <Fragment>
        {this._renderFilters()}
        <View style={styles.body}>{this._renderTable()}</View>
        <View style={styles.buttonContainer}>
          <Button onPress={onNextPress}>Lanjut</Button>
        </View>
      </Fragment>
    );
  }

  _renderTable = () => {
    let {
      rowsPerPage,
      resetPage,
      page,
      selectedTypeOrder,
      selectedDepo,
      selectedSubDistrict,
    } = this.state;
    let {
      selectedDriver,
      selectedPurchases,
      isCapacityLimit,
      onSelectedChange,
      editRITID,
      onOrderListQueryComplete,
    } = this.props;
    const dataKey = 'driverOrders';
    return (
      <Query<DriverOrdersResult, DriverOrdersParams>
        query={GET_DRIVER_ORDERS}
        variables={{
          driverNIK:
            selectedDriver.driverNIK +
            '-' +
            (selectedDriver.loadType === 'GALON'
              ? String(selectedTypeOrder)
              : 'box'),
          ritID: editRITID,
          depotID: selectedDepo ?? undefined,
          subdistrictID: selectedSubDistrict ?? undefined,
        }}
        onCompleted={({driverOrders}) => {
          const totalOrder = driverOrders
            .map(({totalOrder}) =>
              totalOrder.reduce((prev, curr) => {
                return prev + curr.quantity * curr.unitQuantity;
              }, 0),
            )
            .reduce((prev, curr) => prev + curr, 0);

          const sumUom = (uom: 'GALON' | 'BOX') => {
            return driverOrders
              .map(({totalOrder}) =>
                totalOrder
                  .filter((order) => order.uom === uom)
                  .reduce((prev, curr) => {
                    return prev + curr.quantity * curr.unitQuantity;
                  }, 0),
              )
              .reduce((prev, curr) => prev + curr, 0);
          };

          const totalBox = sumUom('BOX');
          const totalGallon = sumUom('GALON');

          onOrderListQueryComplete({
            totalOrder,
            totalBox,
            totalGallon,
          });
        }}
        fetchPolicy="network-only"
        key={dataKey}
        notifyOnNetworkStatusChange
      >
        {({data, loading}) => {
          return (
            <View style={styles.bodyWrapper}>
              <Table
                data={data?.driverOrders ?? []}
                dataCount={data?.driverOrders?.length ?? 0}
                resetPage={resetPage}
                setResetPage={(reset) => this.setState({resetPage: reset})}
                selectedItems={selectedPurchases}
                onSelectItem={onSelectedChange}
                onSelectAllItem={() => {
                  this._onSelectAll((data && data.driverOrders) || []);
                }}
                selectLimit={isCapacityLimit}
                isLoading={loading}
                page={page}
                hideNumber={true}
                showCheckboxes={true}
                onChangePage={(nextPage) => this.setState({page: nextPage})}
                rowsPerPage={rowsPerPage}
                structure={{
                  poNumber: {
                    headerTitle: 'NO.PO',
                  },
                  subDistrict: {
                    headerTitle: 'Kelurahan',
                    colWidth: 120,
                  },
                  customerCode: {
                    headerTitle: 'Kode Pelanggan',
                    colWidth: 120,
                  },
                  storeName: {
                    headerTitle: 'Nama Toko',
                    colWidth: 150,
                  },
                  storeAddress: {
                    headerTitle: 'Alamat Toko',
                    colWidth: 150,
                  },
                  totalOrder: {
                    headerTitle: 'Total Pesanan',
                    headerCenter: true,
                    render: ({totalOrder}) => {
                      let totalOrderAllItem = 0;
                      totalOrder.forEach(
                        ({quantity, unitQuantity}) =>
                          (totalOrderAllItem += quantity * unitQuantity),
                      );
                      return (
                        <View
                          style={{
                            width: 150,
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Popover
                            popoverTrigger={
                              <Text
                                size="small"
                                color={NAVY_BLUE}
                                style={{letterSpacing: 1.5}}
                              >
                                {totalOrderAllItem}
                              </Text>
                            }
                          >
                            <PopoverTableCard
                              data={totalOrder}
                              structure={{
                                order: {
                                  headerTitle: 'Informasi Pesanan',
                                  render: ({
                                    quantity,
                                    unitQuantity,
                                    name,
                                    uom,
                                  }) => {
                                    return (
                                      <View>
                                        <Text
                                          size="small"
                                          style={{letterSpacing: 1.5}}
                                        >
                                          {name} - {unitQuantity}X{quantity}{' '}
                                          {uom}
                                        </Text>
                                      </View>
                                    );
                                  },
                                },
                              }}
                            ></PopoverTableCard>
                          </Popover>
                        </View>
                      );
                    },
                  },
                  orderUOM: {
                    headerTitle: 'Jenis Pesanan',
                    colWidth: 150,
                    render: ({totalOrder}) => {
                      const orderUOMs = totalOrder
                        .map(({uom}) => uom)
                        .filter(
                          (uom, index, self) => self.indexOf(uom) === index,
                        );
                      return orderUOMs.length > 1 ? 'MIXING' : orderUOMs[0];
                    },
                  },
                  orderDate: {
                    headerTitle: 'Tanggal Order',
                    colWidth: 120,
                    processor: (date) => {
                      return new Date(date).toLocaleDateString('id').toString();
                    },
                  },
                }}
                loadMore={() => {}}
                onChangeRowsPerPage={(newRowsPerPage) =>
                  this.setState({
                    rowsPerPage: newRowsPerPage,
                    page: 0,
                  })
                }
              />
            </View>
          );
        }}
      </Query>
    );
  };

  _onSelectAll = (data: Array<DriverOrder>) => {
    let {selectedDriver, setCapacityLimit, onSelectedChange} = this.props;
    let totalQty = 0;
    let lastItemFlag = false;
    let allOrderWithinCapacity = data.filter(({totalOrder}) => {
      totalOrder.forEach(
        ({quantity, unitQuantity, uom}) =>
          uom === selectedDriver.loadType &&
          (totalQty += quantity * unitQuantity),
      );
      if (totalQty < selectedDriver.maxCapacity) {
        return true;
      } else if (totalQty >= selectedDriver.maxCapacity && !lastItemFlag) {
        // For checking if this last item that have reach max capacity
        lastItemFlag = true;
        return true;
      } else {
        return false;
      }
    });
    onSelectedChange(allOrderWithinCapacity);
    setCapacityLimit(true);
  };

  _renderFilters = () => {
    let {selectedSubDistrict, selectedDepo, selectedTypeOrder} = this.state;
    const {selectedDriver, onMixOrderFilterChange} = this.props;
    return (
      <View style={styles.filters}>
        <View style={styles.filterRow}>
          <DepotPicker
            isFilter
            style={styles.filterMargin}
            selectedOption={selectedDepo}
            onChange={(selected) => {
              this.setState({
                selectedDepo: selected ? selected.value : selected,
                resetPage: true,
              });
            }}
            hidden={this.props.role !== 'SUPER_ADMIN'}
          />
          <SubDistrictPicker
            isFilter
            style={[{zIndex: 1}, styles.filterMargin]}
            selectedOption={selectedSubDistrict}
            onChange={(selected) =>
              this.setState({
                selectedSubDistrict: selected ? selected.value : selected,
                selectedSubDistrictLabel: selected ? selected.label : selected,
              })
            }
          />

          {selectedDriver.loadType !== 'BOX' ? (
            <MixOrderPicker
              isFilter
              style={[{zIndex: 1}, styles.filterMargin]}
              selectedOption={selectedTypeOrder}
              onChange={async (selected) => {
                await asyncStorage.saveIsMixOrder(
                  selected ? selected.value : '',
                );
                onMixOrderFilterChange(selected ? selected.value : '');
                this.setState({
                  selectedTypeOrder: selected ? selected.value : selected,
                  resetPage: true,
                });
              }}
            />
          ) : null}
        </View>
        <ResetFilterButton onPress={this._clearFilter} />
      </View>
    );
  };

  _clearFilter = async () => {
    await asyncStorage.saveIsMixOrder('');
    this.setState({
      selectedSubDistrict: null,
      selectedSubDistrictLabel: null,
      selectedDepo: null,
      selectedTypeOrder: null,
    });
  };
}

const styles = StyleSheet.create({
  bodyWrapper: {
    paddingTop: 20,
  },
  body: {
    flex: 1,
    marginTop: 10,
  },
  filters: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingTop: 20,
    zIndex: 3,
  },
  resetFilterButton: {
    backgroundColor: 'transparent',
    borderWidth: 0,
    color: BLACK,
  },
  filterRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  buttonContainer: {
    paddingTop: 10,
    alignItems: 'flex-end',
  },
  showFilterButton: {
    backgroundColor: 'transparent',
    borderWidth: 0,
    color: BLACK,
    marginLeft: -15,
  },
  filterMargin: {
    marginRight: 15,
  },
});

export default OrderList;
