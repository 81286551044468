import {NavigationMenu} from '../';

export const NAVIGATION_ITEMS: Array<NavigationMenu> = [
  {
    logo: 'deliveryMan',
    label: 'Data Driver',
    route: '/drivers',
  },
  {
    logo: 'truck',
    label: 'Kapasitas Kendaraan',
    route: '/vehicles',
  },
  {
    logo: 'businessMan',
    label: 'Assign Driver',
    route: '/assign-driver',
  },
  {
    logo: 'order',
    label: 'Status Pengiriman',
    route: '/orders',
  },
];
