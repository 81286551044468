import React, {Component} from 'react';
import {StyleSheet} from 'react-native';
import {RouteComponentProps} from 'react-router';
import {Mutation} from 'react-apollo';

import {Button, Text, TextField} from '../../core-ui';
import {BackButton, Form, MessagesBox} from '../../components';
import {LandingBackground} from './';
import {graphqlErrorRemover} from '../../helpers';
import {
  CMS_REQUEST_PASSWORD,
  RequestResetPasswordMutation,
} from '../../graphql/queries';
import {PRIMARY} from '../../constants/colors';
import {OperationVariables} from 'apollo-client';

type State = {
  email: string;
};

type Props = RouteComponentProps;

export default class ForgotPasswordPage extends Component<Props, State> {
  state: State = {
    email: '',
  };

  render() {
    return (
      <LandingBackground>{this._renderForgotPassword()}</LandingBackground>
    );
  }

  _renderForgotPassword = () => {
    let {email} = this.state;
    return (
      <Mutation<any, OperationVariables> mutation={CMS_REQUEST_PASSWORD}>
        {(resetPassword, {data, loading, error}) => {
          return (
            <Form onSubmit={() => this._onSubmit(resetPassword)}>
              <BackButton style={styles.backButton} />
              <Text size="large" style={styles.forgotPassword}>
                Lupa Kata Sandi?
              </Text>
              <Text style={styles.subHeader} weight="light">
                Masukan alamat email anda dan kami akan mengirimkan kode
                verifikasi untuk memperbaharui kata sandi anda.
              </Text>
              {data && (
                <MessagesBox
                  message="Link ubah password sudah dikirim"
                  type="success"
                />
              )}
              {error && (
                <MessagesBox
                  message={graphqlErrorRemover(error.message)}
                  type="error"
                />
              )}
              <TextField
                stretch
                label="Email"
                value={email}
                placeholder="Email address"
                onChangeText={this._onEmailTextChange}
                style={{
                  container: {paddingBottom: 60, marginTop: 20},
                  textField: {
                    color: PRIMARY,
                  },
                }}
              />
              <Button
                stretch
                text="Kirim Permintaan"
                color="primary"
                onPress={() => this._onSubmit(resetPassword)}
                isLoading={loading}
              />
            </Form>
          );
        }}
      </Mutation>
    );
  };

  _onSubmit = (resetPassword: RequestResetPasswordMutation) => {
    let {email} = this.state;
    resetPassword({
      variables: {
        email,
        path: '/set-new-password',
      },
    });
  };

  _onEmailTextChange = (text: string) => {
    this.setState({
      email: text,
    });
  };
}

const styles = StyleSheet.create({
  backButton: {
    alignSelf: 'flex-start',
    marginLeft: -20,
    borderWidth: 0,
    backgroundColor: 'transparent',
  },
  forgotPassword: {
    paddingTop: 30,
    paddingBottom: 20,
  },
  subHeader: {
    paddingBottom: 20,
  },
});
