import gql from 'graphql-tag';

export type RawProduct = {
  idProduk: string;
  namaProduk: string;
  namaProgram: string;
  nominalPotonganProgram: number;
  qty: number;
};

export type RawAssignOrder = {
  assignId: string;
  idCustomer: string;
  namaPelanggan: string;
  noPO: string;
  noDO: Nullable<string>;
  uom: 'BOX' | 'GALON';
  potonganPoint: number;
  produk: Array<RawProduct>;
  totalQty: number;
};

export type RITRawData = {
  namaDriver: string;
  nikDriver: string;
  assignOrder: Array<RawAssignOrder>;
};

type DeliveryAction = 'ARRIVED' | 'ON_THE_WAY' | 'RIT_START' | 'CANCEL';

type TTATRitase = {
  targetAction: string;
  deliveryAction: DeliveryAction;
  createdAt: string;
};

type RITRawTTAT = {
  namaDriver: string;
  nikDriver: string;
  TTAT: Array<TTATRitase>;
};

export type RITRawTTATResult = {
  ritRawData: RITRawTTAT;
};

export type RITRawDataResult = {
  ritRawData: RITRawData;
};

export type RITRawDataParams = {
  where: ObjectKey;
};

const RIT_RAW_DATA_RETURN = `
    namaDriver
    nikDriver
    assignOrder{
      assignId
      idCustomer
      namaPelanggan
      noPO
      noDO
      uom
      potonganPoint
      produk{
        idProduk
        namaProduk
        namaProgram
        nominalPotonganProgram
        qty
      }
      totalQty
    }
`;

const RIT_RAW_TTAT_RETURN = `
    namaDriver
    nikDriver
    TTAT{
      tujuan
      aksi
      waktu
    }
`;

export const GET_RIT_RAW_DATA = gql`
  query getRITRawData($where:RITWhereUniqueInput!) {
    ritRawData(where:$where){
      ${RIT_RAW_DATA_RETURN}
    }
  }
`;

export const GET_RIT_RAW_TTAT = gql`
  query getRITRawData($where:RITWhereUniqueInput!) {
    ritRawData(where:$where){
      ${RIT_RAW_TTAT_RETURN}
    }
  }
`;
