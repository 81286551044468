// Dummy Scene for NotFoundPage
import React, {Component} from 'react';
import {View, Image, StyleSheet} from 'react-native';
import {notFound} from '../assets';

export default class NotFoundPage extends Component {
  render() {
    return (
      <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
        <Image
          source={{uri: notFound}}
          style={styles.logo}
          resizeMode="contain"
        />
      </View>
    );
  }
}
const styles = StyleSheet.create({
  logo: {
    width: '50%',
    height: '50%',
  },
});
