import gql from 'graphql-tag';
import {User} from './userQuery';
import {
  INVOICE_USER_FRAGMENT,
  returnedTransactionItems,
} from '../relationalFragments';
import {TransactionItem} from './orderQuery';

export type Invoice = {
  id: string;
  invoiceNumber: string;
  user: User;
  date: string;
  totalPrice: number;
  active: boolean;
};

export type InvoiceListResult = {count: number; invoices: Array<Invoice>};
export type InvoiceListVars = {userID: string; first: number; skip: number};

// TODO: Pagination
export const GET_INVOICE_LIST = gql`
  query getInvoiceList($userID: ID!, $first: Int, $skip: Int) {
    count: dataCount(node: INVOICE, invoiceWhere: {user: {id: $userID}})
    invoices(where: {user: {id: $userID}}, first: $first, skip: $skip) {
      id
      invoiceNumber
      user {
        ...InvoiceUserData
      }
      date
      totalPrice
      active
    }
  }
  ${INVOICE_USER_FRAGMENT}
`;

export type ReturnedTransactionItem = {
  id: string;
  transactionItem: TransactionItem;
  returnReason: string;
  quantity: number;
};

export type ReturnedItem = {
  id: string;
  invoiceNumber: string;
  returnedTransactionItems: Array<ReturnedTransactionItem>;
};

export type ReturnedListResult = {returnedItems: Array<ReturnedItem>};
export type ReturnedListVars = {invoiceNumber: string};

export const GET_RETURNED_ITEMS = gql`
 query getReturnedItems($invoiceNumber: String!) {
  returnedItems(invoiceNumber: $invoiceNumber) {
    invoiceNumber
    id
    ${returnedTransactionItems}
  }
}
`;
