import React, {Children, Component, ReactNode} from 'react';

import Toast from './Toast';

type ToastContextProps = {
  openToast: (variant: ToastVariant, message: string) => void;
  closeToast: () => void;
};

type Props = {
  children: ReactNode;
};

type State = {
  isOpen: boolean;
  variant: ToastVariant;
  message: string;
};

export const ToastContext = React.createContext<Nullable<ToastContextProps>>(
  null,
);

export class ToastProvider extends Component<Props, State> {
  state: State = {
    isOpen: false,
    variant: 'success',
    message: '',
  };

  render() {
    let {children} = this.props;
    let {isOpen, variant, message} = this.state;
    return (
      <ToastContext.Provider
        value={{
          openToast: this._openToast,
          closeToast: this._closeToast,
        }}
      >
        {Children.only(children)}
        <Toast
          isOpen={isOpen}
          variant={variant}
          message={message}
          closeToast={this._closeToast}
        />
      </ToastContext.Provider>
    );
  }

  _openToast = (variant: ToastVariant, message: string) => {
    this.setState({isOpen: true, variant, message});
  };

  _closeToast = () => {
    this.setState({isOpen: false});
  };
}

export const ToastConsumer = ToastContext.Consumer;
