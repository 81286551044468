import React, {ReactElement} from 'react';
import {Animated, View, StyleSheet} from 'react-native';

import {Button} from '../core-ui';
import {BLACK} from '../constants/colors';

type Props = {
  children: ReactElement;
  rightComponent?: ReactElement;
  hidden?: boolean;
};

type State = {
  animatedHeight: Animated.Value;
  isOpen: boolean;
  childrenHeight: number;
};

export default class FilterCollapsible extends React.Component<Props, State> {
  state: State = {
    animatedHeight: new Animated.Value(0),
    isOpen: false,
    childrenHeight: 0,
  };

  render() {
    let {children, rightComponent, hidden} = this.props;
    let {isOpen, animatedHeight} = this.state;

    return (
      !hidden && (
        <Animated.View
          style={[styles.filters, !isOpen && styles.overflowHidden]}
        >
          <View style={styles.filterWrapper}>
            {
              <Button
                inverted
                text="Filter"
                icon="filter_list"
                style={styles.showFilterButton}
                onPress={this._childrenOpen}
              />
            }
            {rightComponent}
          </View>
          <Animated.View style={[styles.filterRow, {height: animatedHeight}]}>
            <View
              onLayout={(item) =>
                this.setState({childrenHeight: item.nativeEvent.layout.height})
              }
            >
              {children}
            </View>
          </Animated.View>
        </Animated.View>
      )
    );
  }

  _childrenOpen = () => {
    let {animatedHeight, isOpen, childrenHeight} = this.state;
    isOpen && this.setState({isOpen: !isOpen});
    Animated.timing(animatedHeight, {
      duration: 500,
      toValue: isOpen ? 0 : childrenHeight,
    }).start(() => !isOpen && this.setState({isOpen: !isOpen}));
  };
}

const styles = StyleSheet.create({
  showFilterButton: {
    backgroundColor: 'transparent',
    borderWidth: 0,
    color: BLACK,
    marginLeft: -15,
  },
  filters: {
    width: '100%',
    alignItems: 'flex-start',
    paddingTop: 5,
    zIndex: 99,
  },
  filterRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  overflowHidden: {
    overflow: 'hidden',
  },
  filterWrapper: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});
