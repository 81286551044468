export const ID_POSTRESQL_PRO: any = {
    'ckuxqxqg90nb50764goap55xv': {
        depo_title: 'DEPO CIRENDEU',
        depo_id: '302',
    },
    'ckuxqxqj10nfx076490u1zwnu': {
        depo_title: 'DEPO CINANGKA',
        depo_id: '304',
    },
    'ckuxqxqfd0n8g0764r9w75jop': {
        depo_title: 'DEPO ALAM SUTERA',
        depo_id: '305',
    },
    'ckuxqxqm90nll0764z3p0tp4u': {
        depo_title: 'DEPO KEDOYA',
        depo_id: '306',
    },
    'ckuxqxqpx0npe0764u1ukznmv': {
        depo_title: 'DEPO LODAN',
        depo_id: '308'
    },
    'ckuxqxses0qb40764jy4qa9v1': {
        depo_title: 'TVIP MANIS3',
        depo_id: '309',
    },
    'ckuxqxqsa0nyt0764gem8wqzw': {
        depo_title: 'DEPO CIKOKOL',
        depo_id: '311',
    },
    'ckuxqxr020o6c0764iy44hlk9': {
        depo_title: 'DEPO KALIBATA',
        depo_id: '312',
    },
    'ckuxqxr3q0obw0764f9kazsgh': {
        depo_title: 'DEPO BINTARO',
        depo_id: '313',
    },
    'ckuxqxr6v0ofw07644gnw75ll': {
        depo_title: 'DEPO DAAN MOGOT',
        depo_id: '315',
    },
    'ckuxqxr990oja0764nn0dn5st': {
        depo_title: 'DEPO PLUIT',
        depo_id: '316',
    },
    'ckuxqxrbu0okh0764ymxq4e97': {
        depo_title: 'DEPO CIKANDE',
        depo_id: '316',
    },
    'ckuxqxq4r0n1y0764jbpfjpba': {
        depo_title: 'DEPO MANIS',
        depo_id: '318'
    },
    'ckuxqxpxb0mm10764auuaj8uc': {
        depo_title: 'DEPO KELAPA DUA',
        depo_id: '320',
    },
    'ckuxqxsfd0qcx0764d8eoyx5j': {
        depo_title: 'DEPO BALARAJA',
        depo_id: '321'
    },
    'ckuxqxsh70qeo0764uelhyr0u' : {
        depo_title: 'DEPO LENTENG AGUNG',
        depo_id: '322'
    },
    'ckuxqxsi60qf50764zqrmimak': {
        depo_title: 'DEPO DEPOK',
        depo_id: '323'
    },
    'cko8cx1hv0qf40879nlyubcd2': {
        depo_title: 'DEPO PANDEGLANG',
        depo_id: '324',
    },
    'ckuxqxrec0prz0764n187nrq2': {
        depo_title: 'DEPO SERANG',
        depo_id: '336',
    },
  };

  export const ID_POSTRESQL_STG: any = {
    'cko8cwzls0nb408797f3xck1f': {
        depo_title: 'DEPO CIRENDEU',
        depo_id: '302',
    },
    'cko8cwzoi0nfw0879fhldy2hg': {
        depo_title: 'DEPO CINANGKA',
        depo_id: '304',
    },
    'cko8cwzkx0n8f0879kokzzstz': {
        depo_title: 'DEPO ALAM SUTERA',
        depo_id: '305',
    },
    'cko8cwzs10nlk0879irw9odgi': {
        depo_title: 'DEPO KEDOYA',
        depo_id: '306',
    },
    'cko8cwzvf0npd0879zl0w45dz': {
        depo_title: 'DEPO LODAN',
        depo_id: '308'
    },
    'cko8cx1eu0qb30879co9tre6y': {
        depo_title: 'TVIP MANIS3',
        depo_id: '309',
    },
    'cko8cwzxq0nys08791hat3dq8': {
        depo_title: 'DEPO CIKOKOL',
        depo_id: '311',
    },
    'cko8cx04e0o6b0879oss9rp7s': {
        depo_title: 'DEPO KALIBATA',
        depo_id: '312',
    },
    'cko8cx0790obv08790zf76wya': {
        depo_title: 'DEPO BINTARO',
        depo_id: '313',
    },
    'cko8cx0a70ofv08796ewuyt7v': {
        depo_title: 'DEPO DAAN MOGOT',
        depo_id: '315',
    },
    'cko8cx0cq0oj90879go8gs528': {
        depo_title: 'DEPO PLUIT',
        depo_id: '316',
    },
    'cko8cx0er0okg0879g7uf1j8p': {
        depo_title: 'DEPO CIKANDE',
        depo_id: '316',
    },
    'cko8cwzau0n1x0879p2ehepd5': {
        depo_title: 'DEPO MANIS',
        depo_id: '318'
    },
    'cko8cwz3v0mm00879a2oow7qd': {
        depo_title: 'DEPO KELAPA DUA',
        depo_id: '320',
    },
    'cko8cx1f70qcw0879tc2wlpu1': {
        depo_title: 'DEPO BALARAJA',
        depo_id: '321'
    },
    'cko8cx1gx0qen0879ifol66p8' : {
        depo_title: 'DEPO LENTENG AGUNG',
        depo_id: '322'
    },
    'cko8cx1hv0qf40879nlyubcd1': {
        depo_title: 'DEPO DEPOK',
        depo_id: '323'
    },
    'cko8cx1hv0qf40879nlyubcd2': {
        depo_title: 'DEPO PANDEGLANG',
        depo_id: '324',
    },
    'cko8cx0ha0pry08798fxz6hpc': {
        depo_title: 'DEPO SERANG',
        depo_id: '336',
    },
  };

  export const ID_POSTRESQL_BY_ID: any = {
    302: {
        depo_title: 'DEPO CIRENDEU',
        postgre_id_production: 'ckuxqxqg90nb50764goap55xv',
        postgre_id_staging: 'cko8cwzls0nb408797f3xck1f'
    },
    304: {
        depo_title: 'DEPO CINANGKA',
        postgre_id_production: 'ckuxqxqj10nfx076490u1zwnu',
        postgre_id_staging: 'cko8cwzoi0nfw0879fhldy2hg'
    },
    305: {
        depo_title: 'DEPO ALAM SUTERA',
        postgre_id_production: 'ckuxqxqfd0n8g0764r9w75jop',
        postgre_id_staging: 'cko8cwzkx0n8f0879kokzzstz'
    },
    306: {
        depo_title: 'DEPO KEDOYA',
        postgre_id_production: 'ckuxqxqm90nll0764z3p0tp4u',
        postgre_id_staging: 'cko8cwzs10nlk0879irw9odgi'
    },
    308: {
        depo_title: 'DEPO LODAN',
        postgre_id_production: 'ckuxqxqpx0npe0764u1ukznmv',
        postgre_id_staging: 'cko8cwzvf0npd0879zl0w45dz'
    },
    309: {
        depo_title: 'TVIP MANIS3',
        postgre_id_production: 'ckuxqxses0qb40764jy4qa9v1',
        postgre_id_staging: 'cko8cx1eu0qb30879co9tre6y'
    },
    311: {
        depo_title: 'DEPO CIKOKOL',
        postgre_id_production: 'ckuxqxqsa0nyt0764gem8wqzw',
        postgre_id_staging: 'cko8cwzxq0nys08791hat3dq8'
    },
    312: {
        depo_title: 'DEPO KALIBATA',
        postgre_id_production: 'ckuxqxr020o6c0764iy44hlk9',
        postgre_id_staging: 'cko8cx04e0o6b0879oss9rp7s'
    },
    313: {
        depo_title: 'DEPO BINTARO',
        postgre_id_production: 'ckuxqxr3q0obw0764f9kazsgh',
        postgre_id_staging: 'cko8cx0790obv08790zf76wya'
    },
    315: {
        depo_title: 'DEPO DAAN MOGOT',
        postgre_id_production: 'ckuxqxr6v0ofw07644gnw75ll',
        postgre_id_staging: 'cko8cx0a70ofv08796ewuyt7v'
    },
    316: {
        depo_title: 'DEPO PLUIT',
        postgre_id_production: 'ckuxqxr990oja0764nn0dn5st',
        postgre_id_staging: 'cko8cx0cq0oj90879go8gs528'
    },
    317: {
        depo_title: 'DEPO CIKANDE',
        postgre_id_production: 'ckuxqxrbu0okh0764ymxq4e97',
        postgre_id_staging: 'cko8cx0er0okg0879g7uf1j8p'
    },
    318: {
        depo_title: 'DEPO MANIS',
        postgre_id_production: 'ckuxqxq4r0n1y0764jbpfjpba',
        postgre_id_staging: 'cko8cwzau0n1x0879p2ehepd5'
    },
    320: {
        depo_title: 'DEPO KELAPA DUA',
        postgre_id_production: 'ckuxqxpxb0mm10764auuaj8uc',
        postgre_id_staging: 'cko8cwz3v0mm00879a2oow7qd'
    },
    321: {
        depo_title: 'DEPO BALARAJA',
        postgre_id_production: 'ckuxqxsfd0qcx0764d8eoyx5j',
        postgre_id_staging: 'cko8cx1f70qcw0879tc2wlpu1'
    },
    322: {
        depo_title: 'DEPO LENTENG AGUNG',
        postgre_id_production: 'ckuxqxsh70qeo0764uelhyr0u',
        postgre_id_staging: 'cko8cx1gx0qen0879ifol66p8'
    },
    323: {
        depo_title: 'DEPO DEPOK',
        postgre_id_production: 'ckuxqxsi60qf50764zqrmimak',
        postgre_id_staging: 'cko8cx1hv0qf40879nlyubcd1'
    },
    324: {
        depo_title: 'DEPO PANDEGLANG',
        postgre_id_production: 'cko8cx1hv0qf40879nlyubcd2',
        postgre_id_staging: 'cko8cx1hv0qf40879nlyubcd2'
    },
    336: {
        depo_title: 'DEPO SERANG',
        postgre_id_production: 'ckuxqxrec0prz0764n187nrq2',
        postgre_id_staging: 'cko8cx0ha0pry08798fxz6hpc'
    },
  };