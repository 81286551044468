import {DataValue} from 'react-apollo';

type Params<T> = {
  query: any;
  variables: T;
  dataKey: string;
};

export default <
  TData extends ObjectKey = {},
  TVariables extends ObjectKey = {}
>(
  fetchMoreFn: (args: any) => void,
  {query, variables, dataKey}: Params<TVariables>,
) => {
  fetchMoreFn({
    query,
    variables,
    updateQuery: (
      prev: DataValue<TData>,
      {fetchMoreResult}: {fetchMoreResult: DataValue<TData, {}>},
    ) => {
      console.log(`fetchMoreResult`, fetchMoreResult)
      if (!fetchMoreResult) {
        return prev;
      }
      let items = prev[dataKey] || [];
      let nextItems = fetchMoreResult[dataKey] || [];
      let result = {
        ...prev,
      }
      let tempItems:any = null;
      let tempNextItems:any = null;
      if (typeof items === 'object' && typeof nextItems === 'object') {
        tempItems = {...items};
        tempNextItems = {...nextItems}
      }
      if (dataKey === 'deliveries' && tempItems !== null && tempNextItems !== null) {
        if (result.deliveries) {
          result = {
            ...prev,
            [dataKey]: {
              ...prev[dataKey],
              ritTransactions: [...tempItems.ritTransactions, ...tempNextItems.ritTransactions]
            }
          }
        }
        return result
      }
      if (dataKey !== 'deliveries') {
        result = {
          ...prev,
          [dataKey]: [...items, ...nextItems],
        }
      }
      return result;
    },
  });
};
