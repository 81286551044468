import gql from 'graphql-tag';

type TransactionArgs = {id?: string; poNumber: string};
export type CUDDriverRITResult = {id: string};
export type CreateDriverRITArgs = {
  driverNIK: string;
  transactions: Array<TransactionArgs>;
  transactionArrangement: Array<string>;
};

export type EditDriverRITArgs = {
  where: {id: string};
  transactions: Array<TransactionArgs>;
  transactionArrangement: Array<string>;
};

export type DeleteDriverRITArgs = {
  where: {id: string};
};

export const CREATE_DRIVER_RIT = gql`
  mutation createDriverRIT(
    $driverNIK: String!
    $transactions: [TransactionArgs!]!
    $transactionArrangement: [String!]!
  ) {
    createDriverRIT(
      driverNIK: $driverNIK
      transactions: $transactions
      transactionArrangement: $transactionArrangement
    ) {
      id
    }
  }
`;

export const EDIT_DRIVER_RIT = gql`
  mutation createDriverRIT(
    $where: RITWhereUniqueInput!
    $transactions: [TransactionArgs!]!
    $transactionArrangement: [String!]!
  ) {
    editDriverRIT(
      where: $where
      transactions: $transactions
      transactionArrangement: $transactionArrangement
    ) {
      id
    }
  }
`;

export const DELETE_DRIVER_RIT = gql`
  mutation createDriverRIT($where: RITWhereUniqueInput!) {
    deleteDriverRIT(where: $where) {
      id
    }
  }
`;
