import React, {Children, ReactElement, Component} from 'react';

import {View, StyleSheet, StyleProp, ViewStyle} from 'react-native';
import {
  Tabs as MTabs,
  Tab as MTab,
  withStyles,
  WithStyles,
  createStyles,
} from '@material-ui/core';
import {TabProps} from '@material-ui/core/Tab';
import {TabsProps as MTabsProps} from '@material-ui/core/Tabs';

import {TABS} from '../constants/colors';
import {FONT_SIZE} from '../constants/textPresets';

type TabsProps = WithStyles<typeof styles> &
  MTabsProps & {
    selectedIndex: number;
    headerWrapperStyle?: StyleProp<ViewStyle>;
    noGreyBar?: boolean;
  };

export const Tab = (_props: TabProps) => null;

class Tabs extends Component<TabsProps> {
  static defaultProps = {
    value: 0,
    selectedIndex: 0,
  };
  render() {
    let {
      children,
      classes,
      noGreyBar,
      headerWrapperStyle,
      selectedIndex,
      value,
      ...otherTabsProps
    } = this.props;

    let content = null;

    let tabsStyle = {
      root: classes.tabsRoot,
      indicator: classes.tabsIndicatorDefault,
    };
    let tabStyle = {
      root: classes.tabRootDefault,
      label: classes.label,
      selected: classes.tabSelectedDefault,
    };

    let headerChildren = Children.map(children, (child, i: number) => {
      if (child) {
        let {type, props: childProps} = child as ReactElement<TabProps>;
        if (type === Tab) {
          let {
            label,
            disabled,
            children: grandChildren,
            ...otherTabProps
          } = childProps;
          let selected = i === selectedIndex;
          if (selected) {
            content = grandChildren;
          }
          return (
            <MTab
              label={label}
              disabled={disabled}
              classes={tabStyle}
              {...otherTabProps}
            />
          );
        }
      }
      return null;
    });
    let header = (
      <View style={headerWrapperStyle}>
        <MTabs
          classes={tabsStyle}
          value={selectedIndex || value}
          {...otherTabsProps}
        >
          {headerChildren}
        </MTabs>
        {noGreyBar ? null : <View style={nativeStyles.greyBar} />}
      </View>
    );
    return (
      <View style={nativeStyles.flex}>
        {header}
        <View style={nativeStyles.flex}>{content}</View>
      </View>
    );
  }
}

const nativeStyles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  greyBar: {
    marginTop: -1,
    zIndex: -1,
    borderBottomWidth: 1,
    borderBottomColor: TABS.SEPARATOR,
  },
});

const styles = createStyles({
  tabsRoot: {
    minHeight: 40,
  },
  label: {
    fontFamily: 'Rubik-Regular',
    fontSize: FONT_SIZE.small,
    fontWeight: 'bold',
  },
  tabRootDefault: {
    color: TABS.DEFAULT,
    textTransform: 'none',
    minWidth: 0,
    '&:hover': {
      color: TABS.ACTIVE,
    },
  },
  tabsIndicatorDefault: {
    backgroundColor: TABS.ACTIVE,
  },
  tabSelectedDefault: {
    color: TABS.ACTIVE,
    fontFamily: 'Rubik-Regular',
  },
});

export default withStyles(styles)(Tabs);
