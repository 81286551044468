import React from 'react';
import {View, StyleSheet, ScrollView} from 'react-native';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import jwt from 'jsonwebtoken';

import {DEFAULT_ROUTES, RouteItem, AUTHENTICATED_ROUTES} from './routeUtils';
import {SetNewPasswordScene} from '../scenes';
import {KEY} from '../constants/key';
import {ALICE_BLUE} from '../constants/colors';
import {Navbar as OriginNavbar} from '../components/Navbar';
import {Query} from '../components';
import {GET_PROVIDER_INFO} from '../graphql/queries/providerQuery';

function PageFrame({content}: {content: JSX.Element}) {
  return (
    <View style={styles.pageFrame}>
      <ScrollView contentContainerStyle={styles.flex}>{content}</ScrollView>
    </View>
  );
}

function Navbar() {
  return (
    <View style={styles.navbarContainer}>
      <OriginNavbar />
    </View>
  );
}

export default function MainRoute() {
  const renderRoutes = (routes: Array<RouteItem>, role?: string) => {
    return (
      <View style={[styles.flex, {height: '100%'}]}>
        <Switch>
          {routes.map(({nickname, component: C, ...routeInfo}, i) => {
            return (
              <Route
                key={i}
                render={(props) => (
                  <C {...props} nickname={nickname} role={role} />
                )}
                {...routeInfo}
              />
            );
          })}
        </Switch>
      </View>
    );
  };

  const renderRoutesWithNavbar = (routes: Array<RouteItem>, role: string) => (
    <View style={styles.rootContainer}>
      <Navbar />
      <PageFrame content={renderRoutes(routes, role)} />
    </View>
  );

  const renderRoutesResetPassword = () => (
    <Route exact path="/:token" component={SetNewPasswordScene} />
  );

  const checkPathName = () => {
    const getToken = window.location.pathname.slice(1);
    try {
      const resultToken: any = jwt.verify(getToken, KEY);
      return resultToken && resultToken.page === 'reset-password';
    } catch (error) {
      return false;
    }
  };

  return (
    <Query<{info: {role: string}}> query={GET_PROVIDER_INFO}>
      {({data, refetch}) => {
        const isResetPasswordToken = checkPathName();
        const isCmsAuthenticated = data && data.info;

        const routeList = isCmsAuthenticated
          ? AUTHENTICATED_ROUTES
          : DEFAULT_ROUTES;
        return (
          <AuthenticatedContext.Provider value={refetch}>
            <BrowserRouter>
              {isCmsAuthenticated
                ? renderRoutesWithNavbar(routeList, data?.info?.role || '')
                : isResetPasswordToken
                ? renderRoutesResetPassword()
                : renderRoutes(routeList)}
            </BrowserRouter>
          </AuthenticatedContext.Provider>
        );
      }}
    </Query>
  );
}

export const AuthenticatedContext = React.createContext(() => {});

const styles = StyleSheet.create({
  flex: {flex: 1},
  rootContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  pageFrame: {
    paddingLeft: 80,
    flex: 1,
    backgroundColor: ALICE_BLUE,
  },
  navbarContainer: {
    zIndex: 100,
  },
});
