import gql from 'graphql-tag';
import {VehicleType} from './vehicleTypeQuery';

export type Vehicle = {
  id: string;
  licensePlate: string;
  vehicleType: VehicleType;
  brandName?: string;
  containerType: string;
  loadType: 'BOX' | 'GALON';
  vehicleStatus: boolean;
  active: boolean | true;
};

export type VehicleListResult = {vehicles: Array<Vehicle>; count: number};

export type VehicleListParams = {
  where?: ObjectKey;
  first?: number;
  skip?: number;
};

const vehicleFragment = gql`
  fragment VehicleFragment on Vehicle {
    id
    licensePlate
    vehicleType {
      typeID
      typeName
      capacity
      info
    }
    brandName
    containerType
    loadType
    vehicleStatus
    active
  }
`;

export const GET_VEHICLES = gql`
  query getVehicles($where: VehicleWhereInput, $skip: Int, $first: Int) {
    vehicles(where: $where, skip: $skip, first: $first) {
      ...VehicleFragment
      __typename
    }
    count: dataCount(node: VEHICLE, vehicleWhere: $where)
  }
  ${vehicleFragment}
`;
