import React from 'react';
import {Text, Loading} from '../../../core-ui';
import {View, StyleSheet} from 'react-native';
import {Query} from '../../../components';
import {RouteComponentProps} from 'react-router';
import {
  RITRawDataParams,
  GET_RIT_RAW_TTAT,
  RITRawTTATResult,
} from '../../../graphql/queries';
import ErrorScreen from '../../../components/ErrorScreen';

type MatchParams = {id: string};
type OwnProps = {};
type Props = RouteComponentProps<MatchParams> & OwnProps;

export class RITRawTTATScene extends React.Component<Props, {}> {
  render() {
    let id = this.props.match.params.id;
    let dataKey = 'ritRawTTAT';
    return (
      <Query<RITRawTTATResult, RITRawDataParams>
        query={GET_RIT_RAW_TTAT}
        variables={{
          where: {id},
        }}
        fetchPolicy="network-only"
        key={dataKey}
      >
        {({data, loading, error}) => {
          if (loading) {
            return (
              <View style={styles.emptyContainer}>
                <Loading />
              </View>
            );
          } else if (error) {
            return (
              <View style={styles.emptyContainer}>
                <ErrorScreen detailMessage={error.message} />
              </View>
            );
          } else {
            return (
              <View>
                <Text>{data && JSON.stringify(data.ritRawData, null, 2)}</Text>
              </View>
            );
          }
        }}
      </Query>
    );
  }
}
const styles = StyleSheet.create({
  emptyContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
