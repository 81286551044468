import gql from 'graphql-tag';
import {Vehicle} from './vehicleQuery';

type DeliveryOrderNumberWithFlag = {
  doNumber: string;
  completed: boolean;
  canceled: boolean;
  invoiceNumber: string;
};

type Depot = {
  id: string;
  depotID: string;
  title: string;
}

export type Driver = {
  id: string;
  driverCode: string;
  driverName: string;
  driverNIK: string;
  displayPassword?: string;
  telephone: string;
  gender: 'MALE' | 'FEMALE';
  vehicle: Vehicle;
  birthDate: string | Date;
  joinDate: string | Date;
  resignDate: string | Date;
  loaded: number;
  depot: Depot;
  deliveryOrder: Array<DeliveryOrderNumberWithFlag>;
};

export type DriverListResult = {drivers: Array<Driver>; count: number};

export type DriverListParams = {
  where?: ObjectKey;
  first?: number;
  skip?: number;
};

const driverFragment = gql`
  fragment DriverFragment on Driver {
    id
    driverCode
    driverName
    driverNIK
    displayPassword
    telephone
    gender
    birthDate
    joinDate
    resignDate
    vehicle {
      licensePlate
      brandName
      vehicleStatus
      containerType
      loadType
      vehicleType {
        typeName
        capacity
      }
    }
    loaded
    deliveryOrder {
      doNumber
      invoiceNumber
      canceled
      completed
    }
    depot {
      id
      depotID
      title
    }
    active
  }
`;

export const GET_DRIVERS = gql`
  query getDrivers($where: DriverWhereInput, $skip: Int, $first: Int) {
    drivers(where: $where, skip: $skip, first: $first) {
      ...DriverFragment
      __typename
    }
    count: dataCount(node: DRIVER, driverWhere: $where)
  }
  ${driverFragment}
`;
