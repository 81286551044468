import gql from 'graphql-tag';

export type TotalOrder = {
  name: string;
  quantity: number;
  unitQuantity: number;
  uom: 'GALON' | 'BOX';
};

export type DriverOrder = {
  id: string;
  poNumber: string;
  storeName: string;
  customerCode: string;
  subDistrict: string;
  storeAddress: string;
  orderDate: string;
  totalOrder: Array<TotalOrder>;
};

export type testDriverOrder = {
  id: string;
  poNumber: string;
  storeName: string;
  customerCode: string;
  subDistrict: string;
  storeAddress: string;
  orderDate: string;
};

const totalOrder = `
  totalOrder {
    name
    quantity
    unitQuantity
    uom
   }
`;

const DRIVER_ORDERS_RETURN_VALUE = `
  id
  poNumber
  storeName
  customerCode
  subDistrict
  storeAddress
  orderDate
  ${totalOrder}
`;

export type DriverOrdersResult = {driverOrders: Array<DriverOrder>};

export type DriverOrdersParams = {
  driverNIK: string;
  ritID?: string;
  depotID?: string;
  subdistrictID?: string;
};

export type RITSelectedOrderParams = {
  where?: ObjectKey;
};

export type RITSelectedOrderResult = {
  ritSelectedOrders: Array<DriverOrder>;
};

export const GET_DRIVER_ORDERS = gql`
  query getDriverOrders($driverNIK: String!, $ritID: ID, $depotID: ID, $subdistrictID: ID){
    driverOrders(where:{ driverNIK: $driverNIK, ritID: $ritID, depotID: $depotID, subdistrictID: $subdistrictID }){
      ${DRIVER_ORDERS_RETURN_VALUE}
    }
  }
`;

export const GET_RIT_SELECTED_ORDERS = gql`
  query getRITSelectedOrders($where:RITWhereUniqueInput){
    ritSelectedOrders(where:$where){
      ${DRIVER_ORDERS_RETURN_VALUE}
    }
  }
`;
