import {DataValue} from 'react-apollo';

type Params<T> = {
  query: any;
  variables: T;
  dataKey: string;
  rowsPerPage: number;
  page: number;
};

export default <
  TData extends ObjectKey = {},
  TVariables extends ObjectKey = {}
>(
  fetchMoreFn: (args: any) => void,
  {query, variables, dataKey, rowsPerPage, page}: Params<TVariables>,
) => {
  fetchMoreFn({
    query,
    variables,
    updateQuery: (
      prev: DataValue<TData>,
      {fetchMoreResult}: {fetchMoreResult: DataValue<TData, {}>},
    ) => {
      if (!fetchMoreResult) {
        return prev;
      }

      let items = (prev[dataKey] || []) as Array<ObjectKey>;
      let prevItems = items.slice(0, page * rowsPerPage);

      let nextItems = (fetchMoreResult[dataKey] || []) as Array<ObjectKey>;

      return {
        ...prev,
        [dataKey]: [...prevItems, ...nextItems],
        count: fetchMoreResult.count,
      };
    },
  });
};
