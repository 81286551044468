export function formatNumberDate(
  input: Date,
  shortYear: boolean = false,
  showTime: boolean = true,
) {
  let year = shortYear ? '2-digit' : 'numeric';
  let time = showTime
    ? {
        hour: '2-digit',
        minute: '2-digit',
      }
    : {};
  return input.toLocaleDateString('id-ID', {
    year,
    month: '2-digit',
    day: '2-digit',
    ...time,
  });
}

export function formatDateTimeWithoutTimezone(
  input: Date | number,
  shortYear = false,
  showTime = true,
) {
  const year = shortYear ? '2-digit' : 'numeric';
  const time = showTime
    ? {
        hour: '2-digit' as const,
        minute: '2-digit' as const,
      }
    : {};

  return Intl.DateTimeFormat('id-ID', {
    timeZone: 'UTC',
    year,
    month: '2-digit',
    day: '2-digit',
    ...time,
  }).format(input);
}
