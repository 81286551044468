import React, {Component} from 'react';
import {Text} from './';

type Props = {
  timeLeft: number;
  onTimeEnd: () => void;
};

type State = {
  timeLeft: number;
};

export default class Countdown extends Component<Props, State> {
  state: State = {
    timeLeft: this.props.timeLeft,
  };
  _interval: Nullable<NodeJS.Timeout> = null;

  componentDidMount() {
    this._interval = setInterval(() => {
      if (this.state.timeLeft <= 0) {
        this._clearInterval();
        this.props.onTimeEnd();
      }
      this.setState({
        timeLeft: this.state.timeLeft - 1,
      });
    }, 1000);
  }

  componentWillUnmount() {
    this._clearInterval();
  }

  _clearInterval = () => {
    this._interval != null && clearInterval(this._interval);
  };

  render() {
    let {timeLeft} = this.state;
    return <Text>{timeLeft}</Text>;
  }
}
