import {InMemoryCache} from 'apollo-cache-inmemory';
import {ApolloClient} from 'apollo-client';
import {ApolloLink, split} from 'apollo-link';
import {withClientState} from 'apollo-link-state';
import {createUploadLink} from 'apollo-upload-client';
import {setContext} from 'apollo-link-context';
import {getMainDefinition} from '@apollo/client/utilities';

import {asyncStorage} from '../helpers';

import {defaultState} from './localState';
import {
  updateSearchState,
  updateSelectedState,
  loginSuccess,
  logout,
} from './resolvers';
import {API_URI, WS_URI} from '../constants/api';
import {SubscriptionClient} from 'subscriptions-transport-ws';
import {WebSocketLink} from 'apollo-link-ws';

const cache = new InMemoryCache();
const stateLink = withClientState({
  defaults: defaultState,
  resolvers: {
    Mutation: {
      setTableSelectedArray: updateSelectedState,
      updateSearch: updateSearchState,
      loginSuccess,
      logout,
    },
  },
  cache,
});
const authLink = setContext(async (_, {headers}) => {
  let token = await asyncStorage.getToken();
  return {
    headers: {
      ...headers,
      'auth-token': token ? token : '',
    },
  };
});
const httpLink = createUploadLink({
  uri: API_URI,
  credentials: 'same-origin',
});
const wsLink = new WebSocketLink(new SubscriptionClient(WS_URI));
const link = ApolloLink.from([stateLink, authLink, httpLink]);
export const client = new ApolloClient({
  link: split(
    ({query}) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    link,
  ),
  cache,
});

client.onResetStore(() => {
  stateLink.writeDefaults();
  return Promise.resolve();
});
