function descendingSort<T extends ObjectKey>(
  a: T,
  b: T,
  orderBy: keyof T,
): number {
  let checkA =
    typeof a[orderBy] === 'string' ? a[orderBy].toLowerCase() : a[orderBy];
  let checkB =
    typeof b[orderBy] === 'string' ? b[orderBy].toLowerCase() : b[orderBy];
  if (checkB < checkA) {
    return -1;
  }
  if (checkB > checkA) {
    return 1;
  }
  return 0;
}

function getSorting<T extends ObjectKey>(
  isDescending: boolean = true,
  orderBy: string | keyof T,
) {
  return isDescending
    ? (a: T, b: T) => descendingSort<T>(a, b, orderBy)
    : (a: T, b: T) => -descendingSort<T>(a, b, orderBy);
}

export {descendingSort, getSorting};
