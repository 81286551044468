import React, {Fragment} from 'react';
import {
  ApolloConsumer,
  Mutation,
  MutationFunc,
  compose,
  graphql,
} from 'react-apollo';
import {StyleSheet, View} from 'react-native';
import {RouteComponentProps} from 'react-router';

import {ApolloQueryResult, OperationVariables} from 'apollo-client';
import {
  DepotPicker,
  ErrorScreen,
  OrderDetailModal,
  Popover,
  PopoverTableCard,
  Query,
  ResetFilterButton,
  RowsPerPage,
  SearchField,
  Table,
} from '../../components';
import {
  BAHAMA_BLUE,
  BLACK,
  BRIGHT_GREEN,
  DARK_GRAY,
  GRAY,
  NAVY_BLUE,
  RED,
  WHITE,
} from '../../constants/colors';
import {Button, Icon, Modal, Separator, Text} from '../../core-ui';
import {SearchState} from '../../graphql/localState';
import {UPDATE_TRANSACTION} from '../../graphql/mutations';
import {
  DriverListParams,
  DriverListResult,
  GET_DRIVERS,
  GET_ORDER_LIST,
  GET_SEARCH_STATE,
  GET_TOTAL_REMAINING_ORDER,
  Order,
  OrderListParams,
  OrderListResult,
  SearchStateProps,
  TotalRemainingOrderResult,
  UPDATE_SEARCH_STATE,
} from '../../graphql/queries';
import {
  asyncStorage,
  convertDepoToPostgresqlIDpro,
  fetchMoreItems,
  refetchItems,
} from '../../helpers';
import withToast, {ToastContextProps} from '../../helpers/withToast';
import {AssignRIT} from './RIT/AssignRIT/AssignRIT';
import RITList from './RIT/RITList';

type UpdateSearchVariables = {
  searchedString: string;
};
type UpdateSearchData = {
  updateSearch: MutationFunc<{}, UpdateSearchVariables>;
};

type LoadType = 'GALON' | 'BOX';

export type SelectedDriver = {
  driverName: string;
  driverNIK: string;
  maxCapacity: number;
  loadType: LoadType;
};

export type TotalRemainingOrderRefetch = Nullable<
  () => Promise<ApolloQueryResult<TotalRemainingOrderResult>>
>;

type State = {
  resetPage: boolean;
  actionDataID: Nullable<string>;
  actionData: Nullable<Order>;
  rowsPerPage: RowsPerPage;
  page: number;
  isShowRITListDriver: boolean;
  isShowAssignRIT: boolean;
  selectedDriver: null | SelectedDriver;
  selectedRIT?: string;
  isInvoiceVisible: boolean;
  isUpdateVisible: boolean;
  selectedDepo: Nullable<string>;
  nameUserDepo: string;
  idPostresqlDepo: string;
};

type Props = RouteComponentProps &
  UpdateSearchData &
  SearchStateProps &
  ToastContextProps & {role: string};

export class AssignDriverListScene extends React.Component<Props, State> {
  state: State = {
    resetPage: false,
    actionDataID: null,
    actionData: null,
    rowsPerPage: 10,
    page: 0,
    isShowRITListDriver: false,
    isShowAssignRIT: false,
    selectedDriver: null,
    selectedRIT: undefined,
    isInvoiceVisible: false,
    isUpdateVisible: false,
    selectedDepo: null,
    nameUserDepo: '',
    idPostresqlDepo: '',
  };

  async componentDidMount() {
    let nameUserDepo = (await asyncStorage.getName()) || '';
    this.setState({nameUserDepo});

    try {
      this.setState({
        idPostresqlDepo: convertDepoToPostgresqlIDpro(nameUserDepo),
      });
    } catch (error) {
      this.setState({idPostresqlDepo: ''});
    }

    /*
      pastikan untuk di function helper convertDepoToPostgresqlID di ganti staging atau prodction sesuai dengan kebutuhan
    */
  }

  _refetchProductCount: TotalRemainingOrderRefetch = null;

  componentWillUnmount() {
    let {updateSearch} = this.props;
    updateSearch({variables: {searchedString: ''}});
  }

  componentDidUpdate(prevProps: Props) {
    let {
      searchStateQuery: {searchState: prevSearch},
    } = prevProps;
    let {
      searchStateQuery: {searchState},
    } = this.props;
    if (prevSearch && searchState) {
      if (prevSearch.searchedString !== searchState.searchedString) {
        this.setState({resetPage: true});
      }
    }
  }

  _setResetPage = (reset: boolean) => {
    this.setState({resetPage: reset});
  };

  _closeAdd = () => this.setState({isUpdateVisible: false});

  _onActivateError = (error: any) => {
    let {openToast} = this.props;
    let message = 'Data gagal diperbarui.';
    if (error) {
      // add custom error message
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        // case 1 No Node for the model AssignedOrder with value kvabnpqt09bx0a79vj7oj24n for id found.
        let err = error.graphQLErrors[0].message;
        if (
          err.includes('No Node for the model AssignedOrder with value ') &&
          err.includes(' for id found.')
        ) {
          let assignId = err
            .replace('No Node for the model AssignedOrder with value ', '')
            .replace(' for id found.', '');
          message = 'Tidak ditemukan AssignedOrder dengan id ' + assignId;
        }
      }
    }
    openToast && openToast('fail', message);
  };

  _renderSyncModal = (refetchFn: () => void) => {
    let {isUpdateVisible} = this.state;
    return (
      <Mutation<OperationVariables>
        mutation={UPDATE_TRANSACTION}
        onCompleted={() => {
          let {openToast} = this.props;
          openToast && openToast('success', 'Data berhasil diperbarui.');
          refetchFn();
          this.setState({isUpdateVisible: false});
        }}
        onError={(error) => this._onActivateError(error)}
      >
        {(updateDatabase, {loading}) => {
          return (
            <Modal
              isVisible={isUpdateVisible}
              title="Perbarui Data Assign Driver"
              isLoading={loading}
              description={
                <Text size="small" color={DARK_GRAY}>
                  Proses ini akan membutuhkan waktu beberapa saat. Apakah anda
                  yakin ingin meng-update/memperbarui data?{' '}
                </Text>
              }
              children={
                <View style={styles.modalView}>
                  <Button
                    style={{marginRight: 15}}
                    inverted
                    color="secondary"
                    text="Batal"
                    onPress={this._closeAdd}
                    loadingColor={WHITE}
                  />
                  <Button
                    color="primary"
                    text="Ya"
                    onPress={() => updateDatabase()}
                    loadingColor={WHITE}
                    isLoading={loading}
                  />
                </View>
              }
              onClose={this._closeAdd}
            />
          );
        }}
      </Mutation>
    );
  };

  render() {
    return (
      <View style={styles.root}>
        {this._renderRITListModal()}
        {this._renderAssignRIT()}
        {this._renderHeader()}
        {this._renderInvoiceModal()}
        {this._renderFilters()}
        <Separator />
        {this._renderProductCount()}
        {this._renderTable()}
      </View>
    );
  }

  _renderHeader = () => {
    let {
      searchStateQuery: {searchState},
      updateSearch,
    } = this.props;
    return (
      <View style={styles.header}>
        <Text size="xlarge">Assign Driver</Text>
        <View style={styles.flexRow}>
          <SearchField
            value={searchState ? searchState.searchedString : ''}
            onChangeText={(text) =>
              updateSearch({variables: {searchedString: text}})
            }
          />
          <Button onPress={() => this.setState({isUpdateVisible: true})}>
            Update Data
          </Button>
        </View>
      </View>
    );
  };

  _getQueryWhere = () => {
    let {
      searchStateQuery: {searchState},
    } = this.props;
    let {selectedDepo, idPostresqlDepo} = this.state;

    // if (searchState) {
    //   let searchText = searchState.searchedString.toLowerCase();
    //   return {
    //     OR: [{driverName_contains: searchText}],
    //   };
    // }
    // return {};

    return {
      ...(selectedDepo || idPostresqlDepo
        ? {depot: {id: selectedDepo || idPostresqlDepo}}
        : {}),
      OR: [
        {
          driverCode_contains: searchState
            ? searchState.searchedString.toLowerCase()
            : '',
        },
        {
          driverNIK_contains: searchState
            ? searchState.searchedString.toLowerCase()
            : '',
        },
        {
          driverName_contains: searchState
            ? searchState.searchedString.toLowerCase()
            : '',
        },
      ],
      // driverName_contains: searchState
      //   ? searchState.searchedString.toLowerCase()
      //   : ''
    };
  };

  _renderFilters = () => {
    let {selectedDepo} = this.state;
    return (
      this.props.role === 'SUPER_ADMIN' && (
        <View style={styles.filters}>
          <Fragment>
            <View style={styles.filterRow}>
              <DepotPicker
                isFilter
                style={styles.filterMargin}
                selectedOption={selectedDepo}
                onChange={(selected) => {
                  this.setState({
                    selectedDepo: selected ? selected.value : selected,
                    resetPage: true,
                  });
                }}
                hidden={this.props.role !== 'SUPER_ADMIN'}
              />
            </View>
          </Fragment>
          <ResetFilterButton onPress={this._clearFilter} />
        </View>
      )
    );
  };

  _clearFilter = () => {
    this.setState({
      selectedDepo: null,
    });
  };

  _renderProductCount = () => {
    const dataKey = 'totalRemainingOrder';
    return (
      <Query<TotalRemainingOrderResult, {}>
        query={GET_TOTAL_REMAINING_ORDER}
        keyData={dataKey}
        fetchPolicy="network-only"
        disableLoading={true}
      >
        {({data, refetch}) => {
          this._refetchProductCount = refetch;
          if (data) {
            return (
              <View style={styles.productCountContainer}>
                <Text>
                  Total Pesanan :{' '}
                  {
                    <Text color={NAVY_BLUE} weight="bold">
                      {(data.totalRemainingOrder &&
                        data.totalRemainingOrder.totalGalon) ||
                        0}
                    </Text>
                  }{' '}
                  GALON -{' '}
                  {
                    <Text color={NAVY_BLUE} weight="bold">
                      {(data.totalRemainingOrder &&
                        data.totalRemainingOrder.totalBox) ||
                        0}
                    </Text>
                  }{' '}
                  BOX
                </Text>
              </View>
            );
          } else {
            return (
              <View style={styles.productCountContainer}>
                <Text>
                  Total Pesanan :{' '}
                  {
                    <Text color={NAVY_BLUE} weight="bold">
                      0
                    </Text>
                  }{' '}
                  GALON -{' '}
                  {
                    <Text color={NAVY_BLUE} weight="bold">
                      0
                    </Text>
                  }{' '}
                  BOX
                </Text>
              </View>
            );
          }
        }}
      </Query>
    );
  };

  _renderInvoiceModal = () => {
    let {rowsPerPage, actionDataID} = this.state;
    if (actionDataID) {
      return (
        <Query<OrderListResult, OrderListParams>
          query={GET_ORDER_LIST}
          variables={{
            where: {invoiceNumber: actionDataID},
            first: rowsPerPage,
            skip: 0,
          }}
          keyData="orders"
          fetchPolicy="network-only"
        >
          {({data}) => {
            if (data) {
              return (
                <OrderDetailModal
                  order={data.orders[0]}
                  isVisible={this.state.isInvoiceVisible}
                  onClose={this._closeInvoice}
                />
              );
            } else {
              return null;
            }
          }}
        </Query>
      );
    } else {
      return null;
    }
  };

  _openInvoice = (actionDataID: string) => {
    this.setState({isInvoiceVisible: true, actionDataID});
  };

  _closeInvoice = () => {
    this.setState({isInvoiceVisible: false});
  };

  _renderTable = () => {
    let {rowsPerPage, resetPage, page} = this.state;
    const dataKey = 'drivers';
    return (
      <Query<DriverListResult, DriverListParams>
        query={GET_DRIVERS}
        variables={{
          where: this._getQueryWhere(),
          first: rowsPerPage,
          skip: 0,
        }}
        fetchPolicy="network-only"
        keyData={dataKey}
        notifyOnNetworkStatusChange
      >
        {({data, loading, error, fetchMore}) => {
          if (error) {
            return (
              <View style={styles.emptyContainer}>
                <ErrorScreen detailMessage={error.message} />
              </View>
            );
          } else if (data) {
            return (
              <View style={styles.bodyWrapper}>
                {this._renderSyncModal(() =>
                  refetchItems<DriverListResult, DriverListParams>(fetchMore, {
                    query: GET_DRIVERS,
                    variables: {
                      where: this._getQueryWhere(),
                      first: rowsPerPage,
                      skip: page * rowsPerPage,
                    },
                    dataKey,
                    rowsPerPage,
                    page,
                  }),
                )}
                <Table
                  data={data.drivers}
                  dataCount={data.count}
                  resetPage={resetPage}
                  setResetPage={this._setResetPage}
                  isLoading={loading}
                  page={page}
                  onChangePage={(nextPage) => this.setState({page: nextPage})}
                  rowsPerPage={rowsPerPage}
                  structure={{
                    depot: {
                      headerTitle: 'Depo',
                      alias: 'depot.title',
                    },
                    driverCode: {
                      headerTitle: 'Kode Driver',
                      colWidth: 100,
                    },
                    driverName: {
                      headerTitle: 'Nama Driver',
                    },
                    vehicleType: {
                      render: ({vehicle}) => {
                        return (
                          <View style={{width: 120}}>
                            <Text size="small" style={{letterSpacing: 1.5}}>
                              {vehicle.containerType}
                            </Text>
                          </View>
                        );
                      },
                      headerTitle: 'Tipe Kendaraan',
                    },
                    maxCapacity: {
                      render: ({vehicle}) => {
                        return (
                          <View style={{width: 150}}>
                            <Text size="small" style={{letterSpacing: 1.5}}>
                              {vehicle.vehicleType.capacity}
                            </Text>
                          </View>
                        );
                      },
                      headerTitle: 'Kapasitas Armada',
                    },
                    loaded: {
                      headerTitle: 'Muatan Barang',
                      colWidth: 120,
                    },
                    loadType: {
                      render: ({vehicle}) => {
                        return (
                          <View style={{width: 100}}>
                            <Text size="small" style={{letterSpacing: 1.5}}>
                              {vehicle.loadType}
                            </Text>
                          </View>
                        );
                      },
                      headerTitle: 'Jenis Muatan',
                    },
                    notDeliveredCount: {
                      headerTitle: 'Belum Dikirim',
                      colWidth: 100,
                      render: ({deliveryOrder}) => {
                        let notCompletedOrderCount = deliveryOrder.filter(
                          ({completed, canceled}) => !completed && !canceled,
                        ).length;
                        return (
                          <View>
                            <Text size="small" style={{letterSpacing: 1.5}}>
                              {notCompletedOrderCount}
                            </Text>
                          </View>
                        );
                      },
                    },
                    deliveryOrder: {
                      headerTitle: 'Delivery Order',
                      render: ({deliveryOrder}) => {
                        return (
                          <View style={{width: 150}}>
                            <Popover
                              popoverTrigger={
                                <Text
                                  size="small"
                                  color={NAVY_BLUE}
                                  style={{letterSpacing: 1.5}}
                                >
                                  LIHAT SEMUA ({deliveryOrder.length})
                                </Text>
                              }
                            >
                              <PopoverTableCard
                                data={deliveryOrder}
                                structure={{
                                  code: {
                                    headerTitle: `Delivery Order (${deliveryOrder.length})`,
                                    render: ({
                                      doNumber,
                                      completed,
                                      canceled,
                                    }) => {
                                      return (
                                        <View>
                                          {completed ? (
                                            <Text
                                              size="small"
                                              color={BRIGHT_GREEN}
                                              style={{letterSpacing: 1.5}}
                                            >
                                              {doNumber}
                                            </Text>
                                          ) : canceled ? (
                                            <Text
                                              size="small"
                                              color={RED}
                                              style={{letterSpacing: 1.5}}
                                            >
                                              {doNumber}
                                            </Text>
                                          ) : (
                                            <Text
                                              size="small"
                                              color={DARK_GRAY}
                                              style={{letterSpacing: 1.5}}
                                            >
                                              {doNumber}
                                            </Text>
                                          )}
                                        </View>
                                      );
                                    },
                                  },
                                  action: {
                                    noHeaderName: true,
                                    render: ({invoiceNumber}) => {
                                      let onPressWrapped = () =>
                                        this._openInvoice(invoiceNumber);
                                      return (
                                        <Icon
                                          size="small"
                                          name="insert_drive_file"
                                          color={GRAY}
                                          hoverColor={BAHAMA_BLUE}
                                          onPress={onPressWrapped}
                                        ></Icon>
                                      );
                                    },
                                  },
                                }}
                              ></PopoverTableCard>
                            </Popover>
                          </View>
                        );
                      },
                    },
                    action: {
                      noHeaderName: true,
                      render: (assigneeDriver) => {
                        let {
                          driverName,
                          driverNIK,
                          vehicle: {
                            vehicleType: {capacity: maxCapacity},
                          },
                          vehicle: {loadType},
                        } = assigneeDriver;
                        let showRITList = () => {
                          this.setState({
                            isShowRITListDriver: true,
                            selectedDriver: {
                              driverName,
                              driverNIK,
                              maxCapacity,
                              loadType,
                            },
                            selectedRIT: undefined,
                          });
                        };
                        return <Button onPress={showRITList}>Assign</Button>;
                      },
                    },
                  }}
                  loadMore={({first, skip}) => {
                    fetchMoreItems(fetchMore, {
                      query: GET_DRIVERS,
                      variables: {
                        where: this._getQueryWhere(),
                        first,
                        skip,
                      },
                      dataKey,
                    });
                  }}
                  onChangeRowsPerPage={(newRowsPerPage) =>
                    this.setState({
                      rowsPerPage: newRowsPerPage,
                      page: 0,
                    })
                  }
                />
              </View>
            );
          }
          return null;
        }}
      </Query>
    );
  };

  _closeRITList = () => {
    this.setState({isShowRITListDriver: false});
  };
  _closeAssignRIT = () => {
    this.setState({isShowAssignRIT: false});
  };

  _renderRITListModal = () => {
    let {isShowRITListDriver, selectedDriver} = this.state;
    if (selectedDriver) {
      return (
        <Modal
          isVisible={isShowRITListDriver}
          title={`Assign Driver - ${selectedDriver &&
            selectedDriver.driverName.toUpperCase()}`}
          fullWidth={true}
          maxWidth="md"
          children={
            <View>
              <View style={{marginBottom: 20}}>
                <RITList
                  totalRefetchQuery={this._refetchProductCount}
                  selectedDriver={selectedDriver}
                  closeRITModal={this._closeRITList}
                  onEditRIT={(ritID: string) => {
                    this.setState({
                      isShowRITListDriver: false,
                      isShowAssignRIT: true,
                      selectedRIT: ritID,
                    });
                  }}
                />
              </View>
              <View
                style={{flexDirection: 'row', justifyContent: 'flex-start'}}
              >
                <Button
                  color="primary"
                  text="+ Tambah RIT"
                  onPress={this._showNewAssignRIT}
                  loadingColor={WHITE}
                />
              </View>
            </View>
          }
          onClose={this._closeRITList}
        />
      );
    }
    return null;
  };

  _showNewAssignRIT = () => {
    this.setState({
      isShowRITListDriver: false,
      isShowAssignRIT: true,
      selectedRIT: undefined,
    });
  };

  _renderAssignRIT = () => {
    let {isShowAssignRIT, selectedDriver, selectedRIT} = this.state;

    return (
      <ApolloConsumer>
        {(client) => (
          <Modal
            isVisible={isShowAssignRIT}
            title={`Assign Driver - ${selectedDriver &&
              selectedDriver.driverName.toUpperCase()}`}
            maxWidth="xl"
            children={
              selectedDriver && (
                <AssignRIT
                  totalRefetchQuery={this._refetchProductCount}
                  selectedDriver={selectedDriver}
                  editRITID={selectedRIT}
                  closeAssignModal={this._closeAssignRIT}
                  apolloClient={client}
                  role={this.props.role}
                />
              )
            }
            onClose={this._closeAssignRIT}
          />
        )}
      </ApolloConsumer>
    );
  };
}

const filterStyle = StyleSheet.create({
  filterButton: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  resetFilterButton: {
    backgroundColor: 'transparent',
    borderWidth: 0,
    color: BLACK,
  },
});

const styles = StyleSheet.create({
  root: {
    paddingVertical: 40,
    paddingHorizontal: 80,
  },
  header: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 20,
  },
  productCountContainer: {
    height: 30,
    alignItems: 'flex-end',
  },
  flexRow: {
    flexDirection: 'row',
  },
  historyWrapper: {
    justifyContent: 'center',
    paddingLeft: 15,
    marginLeft: 15,
  },
  bodyWrapper: {
    paddingTop: 20,
  },
  actionTableWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  cellText: {letterSpacing: 1.5},
  emptyContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  showFilterButton: {
    backgroundColor: 'transparent',
    borderWidth: 0,
    color: BLACK,
    marginLeft: -15,
  },
  filters: {
    alignItems: 'flex-start',
    paddingTop: 10,
    zIndex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  filterRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  filterMargin: {
    marginRight: 15,
  },
  modalView: {flexDirection: 'row', justifyContent: 'flex-end'},
});

export default compose(
  graphql<{}, SearchState, {}, SearchStateProps>(GET_SEARCH_STATE, {
    name: 'searchStateQuery',
  }),
  graphql<{}, UpdateSearchData, {}, UpdateSearchData>(UPDATE_SEARCH_STATE, {
    name: 'updateSearch',
  }),
  withToast,
)(AssignDriverListScene);
