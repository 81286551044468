import {ApolloCache} from 'apollo-cache';
import {asyncStorage} from '../../helpers';
import {client} from '../../graphql/client';

import {UserContent} from '../localState';

export let loginSuccess = async (
  _obj: ObjectKey,
  props: UserContent,
  {cache}: {cache: ApolloCache<any>},
) => {
  let {userID, email, name, role, token} = props;
  let data = {
    userState: {
      __typename: 'userState',
      userID,
      email,
      name,
      token,
      role,
    },
  };
  await asyncStorage.saveToken(token);
  await asyncStorage.saveRole(role);
  await asyncStorage.saveName(name);
  cache.writeData({data});
  return null;
};

export let logout = async (_obj: ObjectKey) => {
  await asyncStorage.removeToken();
  await asyncStorage.removeRole();
  await asyncStorage.removeName();
  await asyncStorage.removeIsMixOrder();
  client.resetStore();
  return null;
};
